import { BaseLayout } from 'app/components/common/BaseLayout'
import { media } from 'parkway-web-common'
import styled, { css } from 'styled-components'

interface HeaderProps {
  $isTwoColumnsLayout: boolean
}

export default styled(BaseLayout.Content)<HeaderProps>`
  // overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  @media only screen and ${media.xl} {
    ${props =>
      props?.$isTwoColumnsLayout &&
      css`
        padding: 0;
      `}
  }
`
