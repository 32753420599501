import { BaseCol } from 'app/components/common/BaseCol'
import { useInView } from 'react-intersection-observer'
import * as S from './styles'
import * as STablet from './styles.tablet'
import { CONTACT_PATH } from 'app/components/router/route-path'
import { useNavigate } from 'react-router'
import { useResponsive } from 'parkway-web-common'
const VelocityHome = () => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const navigate = useNavigate()
  const { isDesktop } = useResponsive()
  if (isDesktop) {
    return (
      <S.DataWrapper ref={ref}>
        <S.DataLeftSideWrapper xl={12}>
          <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
            <S.TextTitleLeftSideData>
              {'CyberDX - AI Empower.'}
            </S.TextTitleLeftSideData>
          </BaseCol>
        </S.DataLeftSideWrapper>
        <S.DataRightSideWrapper xl={12}>
          <S.LeftSideTextWrapper
            className={inView ? 'text-opacity-left-to' : ''}
          >
            <S.TextDescriptionLeftSideData>
              {`CyberDX distinguishes itself in the AI solutions industry by offering a special and unique set of features and benefits, including industry-specific solutions, full-stack AI solutions, human-AI collaboration, explainable AI, global expertise with local reach, a commitment to innovation, and a customer-centric approach.`}
            </S.TextDescriptionLeftSideData>
          </S.LeftSideTextWrapper>
          <S.LeftSideTextWrapper
            className={inView ? 'text-opacity-left-to' : ''}
          >
            <S.TextDescriptionLeftSideData>
              {`CyberDX AI solutions offer a unique and valuable set of features and benefits that can help businesses of all sizes achieve their goals. If you are looking for an AI solution provider to help you transform your business, CyberDX is a great option.`}
            </S.TextDescriptionLeftSideData>
          </S.LeftSideTextWrapper>
          <S.LeftSideTextWrapper>
            <S.ButtonVelocity
              className={inView ? 'text-opacity-left-to' : ''}
              onClick={() => navigate(CONTACT_PATH)}
            >
              {'Learn More'}
            </S.ButtonVelocity>
          </S.LeftSideTextWrapper>
        </S.DataRightSideWrapper>
      </S.DataWrapper>
    )
  }

  return (
    <STablet.DataWrapper ref={ref}>
      <STablet.DataLeftSideWrapper>
        <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
          <STablet.TextTitleLeftSideData>
            {'CyberDX - AI Empower.'}
          </STablet.TextTitleLeftSideData>
        </BaseCol>
      </STablet.DataLeftSideWrapper>

      <STablet.DataRightSideWrapper>
        <STablet.LeftSideTextWrapper
          className={inView ? 'text-opacity-left-to' : ''}
        >
          <STablet.TextDescriptionLeftSideData>
            {`CyberDX distinguishes itself in the AI solutions industry by offering a special and unique set of features and benefits, including industry-specific solutions, full-stack AI solutions, human-AI collaboration, explainable AI, global expertise with local reach, a commitment to innovation, and a customer-centric approach.`}
          </STablet.TextDescriptionLeftSideData>
        </STablet.LeftSideTextWrapper>
        <STablet.LeftSideTextWrapper
          className={inView ? 'text-opacity-left-to' : ''}
        >
          <STablet.TextDescriptionLeftSideData>
            {`CyberDX AI solutions offer a unique and valuable set of features and benefits that can help businesses of all sizes achieve their goalSTablet. If you are looking for an AI solution provider to help you transform your business, CyberDX is a great option.`}
          </STablet.TextDescriptionLeftSideData>
        </STablet.LeftSideTextWrapper>
        <STablet.LeftSideTextWrapper>
          <STablet.ButtonVelocity
            className={inView ? 'text-opacity-left-to' : ''}
            onClick={() => navigate(CONTACT_PATH)}
          >
            {'Learn More'}
          </STablet.ButtonVelocity>
        </STablet.LeftSideTextWrapper>
      </STablet.DataRightSideWrapper>
    </STablet.DataWrapper>
  )
}
export default VelocityHome
