import { BaseCol } from 'app/components/common/BaseCol'
import * as S from './styles'
import * as STablet from './styles.tablet'
import { BaseRow } from 'app/components/common/BaseRow'
import { ItemDataAbsoluteComponent } from './item-component'
import { useInView } from 'react-intersection-observer'
import { useResponsive } from 'parkway-web-common'
import {
  DATA_SOLUTIONS,
  DATA_SOLUTIONS_LEFT,
  DATA_SOLUTION_RIGHT,
} from 'app/constant'
const DataHome = () => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const { isDesktop } = useResponsive()
  if (isDesktop) {
    return (
      <S.RootWrapper>
        <S.DataWrapper ref={ref} wrap={false}>
          <S.DataLeftSideWrapper xl={9}>
            <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
              <S.TextTitleLeftSideData>
                {'Let CyberDX Take Your Business to Higher Grounds'}
              </S.TextTitleLeftSideData>
            </BaseCol>
            <S.LeftSideTextWrapper
              className={inView ? 'text-opacity-left-to' : ''}
            >
              <S.TextDescriptionLeftSideData>
                {`CyberDX is a leading provider of intelligent software solutions and AI that empowers businesses to achieve their strategic goals and surpass expectations. Our team of experienced software engineers and data scientists leverages the latest advancements in technology to develop and deliver customized solutions that address the specific needs of our clients`}
              </S.TextDescriptionLeftSideData>
            </S.LeftSideTextWrapper>
          </S.DataLeftSideWrapper>
          <S.DataRightSideWrapper xl={15}>
            <S.AreaItemRightSide>
              <BaseRow gutter={17}>
                <BaseCol>
                  {DATA_SOLUTIONS_LEFT.map((item, index) => {
                    return (
                      <ItemDataAbsoluteComponent
                        item={item}
                        inView={inView}
                        key={index}
                      />
                    )
                  })}
                </BaseCol>
                <S.RightAbsoluteDataItem>
                  {DATA_SOLUTION_RIGHT.map((item, index) => {
                    return (
                      <ItemDataAbsoluteComponent
                        item={item}
                        inView={inView}
                        key={index}
                      />
                    )
                  })}
                </S.RightAbsoluteDataItem>
              </BaseRow>
            </S.AreaItemRightSide>
          </S.DataRightSideWrapper>
        </S.DataWrapper>
      </S.RootWrapper>
    )
  }

  return (
    <STablet.RootWrapper>
      <STablet.DataWrapper
        ref={ref}
        wrap={false}
        style={{ flexDirection: 'column' }}
      >
        <STablet.DataLeftSideWrapper>
          <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
            <STablet.TextTitleLeftSideData>
              {'Let CyberDX Take Your Business to Higher Grounds'}
            </STablet.TextTitleLeftSideData>
          </BaseCol>
          <STablet.LeftSideTextWrapper
            className={inView ? 'text-opacity-left-to' : ''}
          >
            <STablet.TextDescriptionLeftSideData>
              {`CyberDX is a leading provider of intelligent software solutions and AI that empowers businesses to achieve their strategic goals and surpass expectationSTablet. Our team of experienced software engineers and data scientists leverages the latest advancements in technology to develop and deliver customized solutions that address the specific needs of our clients`}
            </STablet.TextDescriptionLeftSideData>
          </STablet.LeftSideTextWrapper>
        </STablet.DataLeftSideWrapper>
        <STablet.DataRightSideWrapper>
          <STablet.AreaItemRightSide>
            <BaseRow gutter={[17, 20]} justify={'space-around'}>
              {DATA_SOLUTIONS.map((item, index) => {
                return (
                  <BaseCol key={index} xl={12}>
                    <ItemDataAbsoluteComponent item={item} inView={inView} />
                  </BaseCol>
                )
              })}
            </BaseRow>
          </STablet.AreaItemRightSide>
        </STablet.DataRightSideWrapper>
      </STablet.DataWrapper>
    </STablet.RootWrapper>
  )
}
export default DataHome
