import { useInView } from 'react-intersection-observer'
import * as S from './styles'
import * as STablet from './styles.tablet'
import { BaseCol } from 'app/components/common/BaseCol'
import { useResponsive } from 'parkway-web-common'

const VisionContainer = () => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const { isDesktop } = useResponsive()
  if (isDesktop) {
    return (
      <S.RootWrapper ref={ref}>
        <BaseCol xl={15}>
          <S.TextTitle className={inView ? 'text-opacity-left-to' : ''}>
            {'Our Mission'}
          </S.TextTitle>
          <S.AreaText className={inView ? 'text-opacity-left-to' : ''}>
            <S.DescriptionTitle>{`CyberDX is a pioneering AI and software development company dedicated to harnessing the power of technology to revolutionize industries and empower businesses across Vietnam and the US. We strive to be a trusted partner for our clients, providing innovative and tailored solutions that address their unique challenges and aspirations.`}</S.DescriptionTitle>
          </S.AreaText>
        </BaseCol>
        <S.ContentWrapper>
          <S.ItemWrapper isNoneBottomBorder>
            <S.SmallWrapper className={inView ? 'text-opacity-left-to' : ''}>
              {'Our Story'}
            </S.SmallWrapper>
            <S.BigSizeWrapper>
              <S.AreaText className={inView ? 'text-opacity-left-to' : ''}>
                <S.TextBigSizeLayout>
                  {`CyberDX is a leading provider of software and AI solutions for businesses of all sizes. The company was founded by a group of former employees of Tesla, FPT, and CMC, three of the top technology companies in Vietnam and the US. The founders of CyberDX were drawn together by a shared vision: to create a company that would use technology to solve the world's most pressing problems.`}
                </S.TextBigSizeLayout>
              </S.AreaText>
              <S.AreaText className={inView ? 'text-opacity-left-to' : ''}>
                <S.TextBigSizeLayout>
                  {`From the outset, CyberDX has been committed to innovation. The company has a team of experienced engineers and scientists who are constantly developing new and cutting-edge software and AI solutions. CyberDX solutions are designed to be scalable, adaptable, and cost-effective, ensuring that businesses can make the most of their investment.`}
                </S.TextBigSizeLayout>
              </S.AreaText>
              <S.AreaText className={inView ? 'text-opacity-left-to' : ''}>
                <S.TextBigSizeLayout>
                  {`In addition to its commitment to innovation, CyberDX is also committed to collaboration. The company works closely with its clients to understand their specific needs and develop solutions that are tailored to their unique requirements. CyberDX also partners with other leading technology companies to bring the best possible solutions to its clients.`}
                </S.TextBigSizeLayout>
              </S.AreaText>
            </S.BigSizeWrapper>
          </S.ItemWrapper>
          <S.ItemWrapper>
            <S.BigSizeWrapper>
              <S.AreaText className={inView ? 'text-opacity-left-to' : ''}>
                <S.TextBigSizeLayout>
                  {`Experienced leadership refers to the ability to effectively guide and manage others based on accumulated knowledge, skills, and expertise gained through extensive experience in leadership positions. It encompasses a range of qualities that enable individuals to navigate complex situations, make sound decisions, and inspire others to achieve common goals.`}
                </S.TextBigSizeLayout>
              </S.AreaText>
            </S.BigSizeWrapper>
            <S.SmallWrapper
              isNoneRightBorder
              isLeftBorder
              className={inView ? 'text-opacity-left-to' : ''}
            >
              {'Experienced Leadership'}
            </S.SmallWrapper>
          </S.ItemWrapper>
        </S.ContentWrapper>
      </S.RootWrapper>
    )
  }
  return (
    <STablet.RootWrapper ref={ref}>
      <BaseCol xl={15}>
        <STablet.TextTitle className={inView ? 'text-opacity-left-to' : ''}>
          {'Our Mission'}
        </STablet.TextTitle>
        <STablet.AreaText className={inView ? 'text-opacity-left-to' : ''}>
          <STablet.DescriptionTitle>{`CyberDX is a pioneering AI and software development company dedicated to harnessing the power of technology to revolutionize industries and empower businesses across Vietnam and the USTablet. We strive to be a trusted partner for our clients, providing innovative and tailored solutions that address their unique challenges and aspirations.`}</STablet.DescriptionTitle>
        </STablet.AreaText>
      </BaseCol>
      <STablet.ContentWrapper>
        <STablet.ItemWrapper isNoneBottomBorder>
          <STablet.SmallWrapper
            className={inView ? 'text-opacity-left-to' : ''}
          >
            {'Our Story'}
          </STablet.SmallWrapper>
          <STablet.BigSizeWrapper>
            <STablet.AreaText className={inView ? 'text-opacity-left-to' : ''}>
              <STablet.TextBigSizeLayout>
                {`CyberDX is a leading provider of software and AI solutions for businesses of all sizes. The company was founded by a group of former employees of Tesla, FPT, and CMC, three of the top technology companies in Vietnam and the USTablet. The founders of CyberDX were drawn together by a shared vision: to create a company that would use technology to solve the world's most pressing problems.`}
              </STablet.TextBigSizeLayout>
            </STablet.AreaText>
            <STablet.AreaText className={inView ? 'text-opacity-left-to' : ''}>
              <STablet.TextBigSizeLayout>
                {`From the outset, CyberDX has been committed to innovation. The company has a team of experienced engineers and scientists who are constantly developing new and cutting-edge software and AI solutions. CyberDX solutions are designed to be scalable, adaptable, and cost-effective, ensuring that businesses can make the most of their investment.`}
              </STablet.TextBigSizeLayout>
            </STablet.AreaText>
            <STablet.AreaText className={inView ? 'text-opacity-left-to' : ''}>
              <STablet.TextBigSizeLayout>
                {`In addition to its commitment to innovation, CyberDX is also committed to collaboration. The company works closely with its clients to understand their specific needs and develop solutions that are tailored to their unique requirements. CyberDX also partners with other leading technology companies to bring the best possible solutions to its clients.`}
              </STablet.TextBigSizeLayout>
            </STablet.AreaText>
          </STablet.BigSizeWrapper>
        </STablet.ItemWrapper>
        <STablet.ItemWrapper>
          <STablet.BigSizeWrapper>
            <STablet.AreaText className={inView ? 'text-opacity-left-to' : ''}>
              <STablet.TextBigSizeLayout>
                {`Experienced leadership refers to the ability to effectively guide and manage others based on accumulated knowledge, skills, and expertise gained through extensive experience in leadership positions. It encompasses a range of qualities that enable individuals to navigate complex situations, make sound decisions, and inspire others to achieve common goals.`}
              </STablet.TextBigSizeLayout>
            </STablet.AreaText>
          </STablet.BigSizeWrapper>
          <STablet.SmallWrapper
            isNoneRightBorder
            isLeftBorder
            className={inView ? 'text-opacity-left-to' : ''}
          >
            {'Experienced Leadership'}
          </STablet.SmallWrapper>
        </STablet.ItemWrapper>
      </STablet.ContentWrapper>
    </STablet.RootWrapper>
  )
}
export default VisionContainer
