import R from 'app/assets/R'
import { BaseCard } from 'app/components/common/BaseCard'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  background-color: black;
`

export const DataWrapper = styled(BaseRow)`
  border: 0.5px solid ${colors.grayC4};
  height: 45rem;
  margin-top: 20rem;
`

export const DataLeftSideWrapper = styled(BaseCol)`
  padding: 5rem;
  border-right: 0.5px solid ${colors.grayC4};
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-image: url(${R.images.img_data_home});
  background-repeat: no-repeat, repeat;
  background-size: cover;
`

export const TextTitleLeftSideData = styled.span`
  color: ${colors.white};
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.medium};
`
export const TextDescriptionLeftSideData = styled.span`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.regular};
`

export const DataRightSideWrapper = styled(BaseCol)`
  padding: ${PADDING.xxl};
  position: relative;
`

export const LeftSideTextWrapper = styled(BaseCol)`
  padding-top: ${PADDING.md};
`

export const AreaItemRightSide = styled.div`
  position: absolute;
  top: -8rem;
  left: 10rem;
`

export const RightAbsoluteDataItem = styled.div`
  padding-top: 16rem;
`

export const ItemRightSide = styled(BaseCard)`
  padding: ${PADDING.xxxl} ${PADDING.md}!important;
  background: ${colors.primary};
  height: 26rem;
  width: 240px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 1px 2px 5px ${colors.black} !important;
  .ant-card-body::before {
    display: none;
  }
  .ant-card-body::after {
    display: none;
  }
  .ant-card-body {
    padding: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }
`

export const TextTitleItemPosition = styled.p`
  color: var(--red-orange-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xl};
`

export const TextDescriptionItemPosition = styled.p`
  color: ${colors.grayC4};
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xs};
  margin-top: ${PADDING.xxs};
`

export const BottomLineItem = styled.div`
  background-color: ${colors.grayC4};
  border-radius: ${BORDER_RADIUS};
  height: 5px;
  width: 100%;
  margin-top: ${PADDING.xxl};
`
