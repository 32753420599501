import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const DataWrapper = styled(BaseCol)`
  border: 0.5px solid ${colors.grayC4};
  margin-top: 5rem;
  background-color: black;
`

export const DataLeftSideWrapper = styled(BaseCol)`
  padding: 1rem;
  border-right: 0.5px solid ${colors.grayC4};
  min-height: 15rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-image: url(https://storage.googleapis.com/cyberdx-storage/1.jpg);
  background-repeat: no-repeat, repeat;
  background-size: cover;
`

export const TextTitleLeftSideData = styled.span`
  color: ${colors.white};
  font-size: ${FONT_SIZE.xxxxl};
  font-weight: ${FONT_WEIGHT.medium};
`
export const TextDescriptionLeftSideData = styled.span`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.regular};
  line-height: 24px;
`

export const DataRightSideWrapper = styled(BaseCol)`
  padding: 1rem 1rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

export const LeftSideTextWrapper = styled(BaseCol)`
  padding-top: ${PADDING.xxxl};
`

export const ButtonVelocity = styled(BaseButton)`
  background-color: var(--red-orange-color);
  color: ${colors.black};
  padding: ${PADDING.xxl} ${PADDING.xxxxl};
  border-color: var(--red-orange-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
  transition: all 1s ease;
  &:hover {
    color: ${colors.white} !important;
    border-color: ${colors.white} !important;
    background-color: transparent;
    transition: all 1s ease;
  }
`
