import { BaseLayout } from 'app/components/common/BaseLayout'
import { media } from 'parkway-web-common'
import styled from 'styled-components'

export const LayoutMaster = styled(BaseLayout)`
  height: 100vh;
  background-image: url(https://storage.googleapis.com/cyberdx-storage/2.jpg);
  background-repeat: no-repeat, repeat;
`

export const LayoutMain = styled(BaseLayout)`
  background: transparent;

  @media only screen and ${media.xl} {
    margin-left: unset;
  }
`
