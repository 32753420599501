import { LANGUAGE_SUPPORT, LanguageSupportType } from 'app/i18n/constant'
import { KEY_STORAGE } from '../key'

export const getToken = () => {
  return localStorage.getItem('token')
}

export const refetchToken = () => {
  return localStorage.setItem('token', '')
}

export const setToken = (token: string) => {
  return localStorage.setItem('token', token)
}

export const getCurrentLanguage = () => {
  const currentLanguage = localStorage.getItem(KEY_STORAGE.LANGUAGE)
  if (!currentLanguage) {
    return LANGUAGE_SUPPORT.vi
  }
  return currentLanguage
}

export const setCurrentLanguage = (language: LanguageSupportType | string) => {
  return localStorage.setItem(KEY_STORAGE.LANGUAGE, language)
}
