import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { PaddingMain } from 'app/constant'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  // padding: ${PaddingMain.Desktop.defaultHz} ${PaddingMain.Desktop.defaultHz};
  margin-top: 8rem;
  background-color: rgba(0, 0, 0, 1);
`

export const HeadWrapper = styled(BaseRow)`
  border-top: 0.5px solid ${colors.grayC4};
  border-bottom: 0.5px solid ${colors.grayC4};
`

export const ItemHeader = styled(BaseCol)`
  width: 20% !important;
  padding: 5rem 2rem;
  border: 0.5px solid ${colors.grayC4};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TitlePartner = styled.span`
  color: ${colors.white};
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.regular};
`

export const TitleBranchPartner = styled.span`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.semibold};
  opacity: 0.7;
  text-align: center;
`

export const ContentWrapper = styled(BaseRow)`
  height: 500px;
`

export const LeftContentWrapper = styled(BaseCol)`
  border: 0.5px solid ${colors.grayC4};
  width: 60% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${PADDING.md} 5rem;
  background-image: url(https://storage.googleapis.com/cyberdx-storage/body3.avif);
  background-size: cover;
  .title {
    color: ${colors.white};
    font-size: ${FONT_SIZE.xxxl};
  }
`

export const RightContentWrapper = styled(BaseCol)`
  border: 0.5px solid ${colors.grayC4};
  width: 40% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  .title {
    color: ${colors.white};
    font-size: ${FONT_SIZE.xxxl};
  }
  padding: ${PADDING.md} ${PADDING.xxxxl};
`

export const TextRight = styled.span`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const AreaPaddingWrapper = styled.div`
  padding: ${PADDING.xxxl} ${PADDING.xxxxl};
`

export const ButtonHeader = styled(BaseButton)`
  background-color: var(--red-orange-color);
  color: ${colors.black};
  padding: ${PADDING.xxl} ${PADDING.xxxxl};
  border-color: var(--red-orange-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
  transition: all 1s ease;
  &:hover {
    color: ${colors.white} !important;
    border-color: ${colors.white} !important;
    background-color: transparent;
    transition: all 1s ease;
  }
`
