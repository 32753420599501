const icons = {
    
    empty_list: require("./images/empty_list.png"),
    ic_avatar: require("./images/ic_avatar.png"),
    ic_chat_bot: require("./images/ic_chat_bot.png"),
    ic_customer: require("./images/ic_customer.png"),
    ic_leading: require("./images/ic_leading.png"),
    ic_parkway: require("./images/ic_parkway.png"),
    ic_united_kingdom_flag: require("./images/ic_united_kingdom_flag.png"),
    ic_verified: require("./images/ic_verified.png"),
    ic_vietnam_flag: require("./images/ic_vietnam_flag.png"),
    ic_voucher: require("./images/ic_voucher.png"),
    img_AI: require("./images/img_AI.jpg"),
    img_automation: require("./images/img_automation.jpg"),
    img_chat_bot: require("./images/img_chat_bot.webp"),
    img_chatbot: require("./images/img_chatbot.jpg"),
    img_data_home: require("./images/img_data_home.gif"),
    img_face_detection: require("./images/img_face_detection.jpeg"),
    img_software_development: require("./images/img_software_development.jpeg"),
    playstore_icon: require("./images/playstore_icon.png")
    }
export default icons