import { PaddingMain } from 'app/constant'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const ScrollWrapper = styled.div`
  min-height: 250px;
  padding-bottom: ${PaddingMain.Desktop.defaultHz};
`

export const ContentWrapper = styled.div<{ isTabletDown?: boolean }>`
  padding: ${props =>
    props?.isTabletDown
      ? `0px ${PADDING.md}`
      : `0px ${PaddingMain.Desktop.defaultHz}`};
`
