import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'
import nightModeReducer from 'app/redux/slices/nightModeSlice'
import pwaReducer from 'app/redux/slices/pwaSlice'
import themeReducer from 'app/redux/slices/themeSlice'

export const store = configureStore({
  reducer: {
    nightMode: nightModeReducer,
    theme: themeReducer,
    pwa: pwaReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
