import { ApiClient } from 'app/service/api-service'

export const ContactEndPoint = {
  create: 'contacts/create',
}

export interface IBodyCreateContact {
  company_name: string
  name: string
  email: string[]
  phone: string[]
  descriptions: string
}
export const requestCreateContact = (body: IBodyCreateContact) =>
  ApiClient.Post({ url: ContactEndPoint.create, body })
