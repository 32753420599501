import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import deVi from 'antd/lib/locale/vi_VN'
import { AppRouter } from 'app/components/router/app-router'
import { LanguageSupportType } from 'app/i18n/constant'
import { useAppSelector } from 'app/redux/hooks'
import { getCurrentLanguage } from 'app/service/storage/storage-service'
import GlobalStyle from 'app/styles/GlobalStyle'
import { themeObject } from 'app/styles/themes/themeVariables'
import { HelmetProvider } from 'react-helmet-async'
import './app/i18n'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const App: React.FC = () => {
  const language = getCurrentLanguage() as LanguageSupportType
  const theme = useAppSelector(state => state.theme.theme)
  const { i18n } = useTranslation()

  const getLanguage = () => {
    if (language) {
      i18n.changeLanguage(language)
      return
    }
  }

  useEffect(() => {
    getLanguage()
  }, [])

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : deVi}>
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </>
  )
}
export default App
