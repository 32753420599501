import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { PaddingMain } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const Tile = styled.p`
  color: ${colors.white};
  font-size: ${FONT_SIZE.xxxxl};
  font-weight: ${FONT_WEIGHT.medium};
`

export const ListWrapper = styled(BaseRow)`
  margin-top: ${PADDING.xxxl};
  width: 100%;
  padding: ${PADDING.md} 15rem;
`

export const BaseColItem = styled(BaseCol)``

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  height: 150px !important;
  justify-content: space-between !important;
`

export const TextValueItem = styled.span`
  color: var(--red-orange-color);
  font-size: 60px;
`
export const TextDescriptionItem = styled.span`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xs};
  text-align: center;
`

export const BottomLine = styled.div`
  width: 100%;
  background-color: ${colors.grayC4};
  height: 2px;
  border-radius: ${BORDER_RADIUS};
`

export const RootWrapper = styled(BaseCol)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${PADDING.xxxxl} ${PaddingMain.Desktop.defaultHz};
  margin-top: 10rem;
  background-color: rgb(0, 0, 0, 0.8);
`
