import { WithChildrenProps } from 'parkway-web-common'
import React from 'react'
import { Helmet } from 'react-helmet-async'

export const PageTitle: React.FC<WithChildrenProps> = ({ children }) => {
  return (
    <Helmet>
      <title>CyberDX | {children}</title>
    </Helmet>
  )
}
