import { memo } from 'react'
import { isEqual } from 'lodash'
import SolutionsContainer from 'app/containers/Solutions'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const SolutionsCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.solutions)}</PageTitle>
      <SolutionsContainer />
    </>
  )
}
export const SolutionsPage = memo(SolutionsCpn, isEqual)
