/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { useInView } from 'react-intersection-observer'
import * as S from './styles'
import * as STablet from './styles.tablet'
import { useNavigate } from 'react-router'
import {
  CONTACT_PATH,
  SOLUTIONS_DETAIL_PATH,
} from 'app/components/router/route-path'
import { BaseRow } from 'app/components/common/BaseRow'
import { useResponsive } from 'parkway-web-common'
import { ISolution } from '../SolutionsDetail'
import { DATA_SOLUTIONS } from 'app/constant'
import { useEffect, useRef } from 'react'

const ItemOurService = (item: ISolution) => {
  const { title, isBottomBorderNone, inView, description, iconUrl } = item
  const { isDesktop } = useResponsive()
  const navigate = useNavigate()
  const onPress = () => {
    navigate(SOLUTIONS_DETAIL_PATH, {
      state: item,
    })
  }
  if (isDesktop) {
    return (
      <S.ItemWrapper isBottomBorderNone={isBottomBorderNone} onClick={onPress}>
        <S.LeftItemWrapper>
          <S.IconItem
            src={iconUrl ?? R.images.playstore_icon}
            alt="article"
            preview={false}
            className={inView ? 'text-opacity-left-to' : ''}
          />
        </S.LeftItemWrapper>
        <S.RightItemWrapper>
          <S.AreaText className={inView ? 'text-opacity-left-to' : ''}>
            <S.TitleRightItem>{title}</S.TitleRightItem>
          </S.AreaText>

          <BaseRow className={inView ? 'text-opacity-right-to' : ''}>
            <S.DescriptionRightItem>{description}</S.DescriptionRightItem>
          </BaseRow>
        </S.RightItemWrapper>
      </S.ItemWrapper>
    )
  }
  return (
    <STablet.ItemWrapper
      isBottomBorderNone={isBottomBorderNone}
      onClick={onPress}
    >
      <STablet.LeftItemWrapper>
        <STablet.IconItem
          src={iconUrl ?? R.images.playstore_icon}
          alt="article"
          preview={false}
          className={inView ? 'text-opacity-left-to' : ''}
        />
      </STablet.LeftItemWrapper>
      <STablet.RightItemWrapper>
        <STablet.AreaText className={inView ? 'text-opacity-left-to' : ''}>
          <STablet.TitleRightItem>{title}</STablet.TitleRightItem>
        </STablet.AreaText>

        <BaseRow className={inView ? 'text-opacity-right-to' : ''}>
          <STablet.DescriptionRightItem>
            {description}
          </STablet.DescriptionRightItem>
        </BaseRow>
      </STablet.RightItemWrapper>
    </STablet.ItemWrapper>
  )
}

const SolutionsContainer = () => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const navigate = useNavigate()
  const { isDesktop } = useResponsive()

  const refView = useRef<any>()

  useEffect(() => {
    const timer = setTimeout(() => {
      refView?.current.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    return () => clearTimeout(timer)
  }, [location.pathname])

  if (isDesktop) {
    return (
      <S.RootWrapper ref={ref}>
        <BaseCol
          xl={12}
          className={inView ? 'text-opacity-left-to' : ''}
          ref={refView}
        >
          <S.TextTitle>
            {'Let CyberDX Take Your Business to Higher Grounds'}
          </S.TextTitle>
        </BaseCol>
        <BaseCol>
          <S.AreaText>
            <S.OurService>{'OurService'}</S.OurService>
          </S.AreaText>
        </BaseCol>
        <S.AreaText />
        <S.ListDataWrapper>
          {DATA_SOLUTIONS.map((item, index) => {
            return (
              <ItemOurService
                key={index}
                {...item}
                title={item.title}
                isBottomBorderNone={item?.isBottomBorderNone}
                inView={inView}
                description={item?.description}
                iconUrl={item?.imageUrl}
              />
            )
          })}
        </S.ListDataWrapper>

        <BaseCol>
          <S.FooterWrapper>
            <S.LeftFooterWrapper>
              <S.TextLeftFooterWrapper
                className={inView ? 'text-opacity-left-to' : ''}
              >
                {'We Integrate With Your Ecosystem'}
              </S.TextLeftFooterWrapper>
              <BaseCol className={inView ? 'text-opacity-right-to' : ''}>
                <BaseButtonVariant onClick={() => navigate(CONTACT_PATH)}>
                  {'Get Started'}
                </BaseButtonVariant>
              </BaseCol>
            </S.LeftFooterWrapper>
            <S.RightFooterWrapper
              className={inView ? 'text-opacity-left-to' : ''}
            >
              {`I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you.`}
            </S.RightFooterWrapper>
          </S.FooterWrapper>
        </BaseCol>
      </S.RootWrapper>
    )
  }
  return (
    <STablet.RootWrapper ref={ref}>
      <BaseCol
        xl={12}
        className={inView ? 'text-opacity-left-to' : ''}
        ref={refView}
      >
        <STablet.TextTitle>
          {'Let CyberDX Take Your Business to Higher Grounds'}
        </STablet.TextTitle>
      </BaseCol>
      <BaseCol>
        <STablet.AreaText>
          <STablet.OurService>{'OurService'}</STablet.OurService>
        </STablet.AreaText>
      </BaseCol>
      <STablet.AreaText />
      <STablet.ListDataWrapper>
        {DATA_SOLUTIONS.map((item, index) => {
          return (
            <ItemOurService
              key={index}
              {...item}
              title={item.title}
              isBottomBorderNone={item?.isBottomBorderNone}
              inView={inView}
              description={item?.description}
              iconUrl={item?.imageUrl}
            />
          )
        })}
      </STablet.ListDataWrapper>

      <BaseCol>
        <STablet.FooterWrapper>
          <STablet.LeftFooterWrapper>
            <STablet.TextLeftFooterWrapper
              className={inView ? 'text-opacity-left-to' : ''}
            >
              {'We Integrate With Your Ecosystem'}
            </STablet.TextLeftFooterWrapper>
            <BaseCol className={inView ? 'text-opacity-right-to' : ''}>
              <BaseButtonVariant onClick={() => navigate(CONTACT_PATH)}>
                {'Get Started'}
              </BaseButtonVariant>
            </BaseCol>
          </STablet.LeftFooterWrapper>
          <STablet.RightFooterWrapper
            className={inView ? 'text-opacity-left-to' : ''}
          >
            {`I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you.`}
          </STablet.RightFooterWrapper>
        </STablet.FooterWrapper>
      </BaseCol>
    </STablet.RootWrapper>
  )
}
export default SolutionsContainer
