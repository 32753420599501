import R from 'app/assets/R'
import { ISolution } from 'app/containers/SolutionsDetail'
import { Priority } from 'parkway-web-common'

export type CategoryType = 'apps'

interface Category {
  name: CategoryType
  title: string
}

export const categoriesList: Category[] = [
  {
    name: 'apps',
    title: 'common.apps',
  },
]

export interface Component {
  name: string
  title: string
  url: string
  categories: CategoryType[]
  keywords: string[]
}

export const configComponents: Component[] = [
  {
    name: 'Home',
    title: 'medical-dashboard.protein',
    url: `/home`,
    categories: ['apps'],
    keywords: ['home', 'apps', 'statistics'],
  },
]

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)'
    case Priority.LOW:
      return 'var(--success-color)'
    case Priority.MEDIUM:
      return 'var(--warning-color)'
    case Priority.HIGH:
      return 'var(--error-color)'
    default:
      return 'var(--success-color)'
  }
}

export const PaddingMain = {
  Desktop: {
    defaultHz: '5rem',
  },
}

export const DATA_SOLUTIONS_LEFT: ISolution[] = [
  {
    id: 1,
    title: 'CyberDX Custom Software Development',
    imageUrl: R.images.img_AI,
    isBottomBorderNone: true,

    iconUrl:
      'https://storage.googleapis.com/cyberdx-storage/ic_software_development.jpg',
    titleDesciption: 'Innovative Solutions Tailored to Your Unique Needs',
    textLongDescription:
      'Unlock the full potential of your business with "CyberDX Custom Software Development." Our dedicated team of experts is committed to crafting bespoke software solutions that address your specific challenges and propel your organization toward success.',
    description:
      'A team of experienced software engineers who can develop custom software solutions to meet the specific needs of businesses.',
    listDescriptions: [
      {
        title: '1. High-Quality App Development:',
        imageUrls: [R.images.img_software_development],
        description:
          'CyberDX is committed to creating top-notch, flexible, and powerful mobile applications. We prioritize user experience and performance to ensure your app is not only convenient but also meets all business requirements.',
      },
      {
        title: '2. Diverse Website Development:',
        description:
          'With an experienced team of experts, we offer a range of website development services, from corporate websites to e-commerce platforms. We optimize interactivity and integrate advanced features to enhance your business performance.',
      },
      {
        title: '3. Effective Digital Transformation Consultation:',
        imageUrls: [R.images.img_automation],
        description:
          'We are not just developers; we are strategic partners in your digital transformation journey. CyberDX provides comprehensive consultation to help your business understand the potential and challenges of digital transformation, offering optimal solutions and suitable strategies.',
      },
      {
        title: '4. Quality Expert Team:',
        description:
          'With top-notch programmers, designers, and digital transformation experts, we take pride in delivering professionalism and innovation. We constantly pursue excellence and work closely with clients to ensure every project achieves optimal results.',
      },
    ],
    whyChoose: {
      title: 'Why Choose CyberDX?',
      reasons: [
        {
          title: 'Expertise Across Industries',
          description:
            'Our team boasts expertise in various industries, ensuring a deep understanding of your unique challenges and opportunities.',
        },
        {
          title: 'Collaborative Approach',
          description:
            'We believe in collaboration. Your insights combined with our technical proficiency result in solutions that truly meet your business requirements.',
        },
        {
          title: 'Proven Track Record',
          description:
            'With a track record of successful projects, we bring a wealth of experience to every engagement, ensuring reliability and excellence.',
        },
      ],
    },
    serviceSuport: {
      title: 'Industries We Serve',
      services: [
        {
          title: 'Healthcare',
          description:
            'Develop healthcare solutions that streamline processes, enhance patient care, and meet regulatory requirements.',
        },
        {
          title: 'Finance',
          description:
            'Create secure and scalable financial software, from banking applications to investment platforms.',
        },
        {
          title: 'E-Commerce',
          description:
            'Build robust e-commerce platforms that drive sales, improve user experiences, and adapt to changing market dynamics.',
        },
        {
          title: 'Manufacturing',
          description:
            'Implement software solutions that optimize production processes, inventory management, and supply chain logistics.',
        },
      ],
    },
  },
  {
    id: 2,
    title: 'CyberDX Intelligent Face Detection',
    imageUrl: R.images.img_face_detection,
    isBottomBorderNone: true,

    titleDesciption: 'Revolutionizing Face Recognition Technology',
    iconUrl:
      'https://storage.googleapis.com/cyberdx-storage/ic_face_detection.jpg',
    description:
      'Cutting-edge AI and software solution that allows businesses to accurately identify and detect human faces from various sources, including video streams, images, and still frames. It is a powerful tool with a wide range of applications.',

    textLongDescription:
      'Empower your applications with the cutting-edge "CyberDX Intelligent Face Detection" technology. We bring you a state-of-the-art solution that goes beyond traditional face detection, offering a sophisticated and intelligent system for a wide range of applications.',
    listDescriptions: [
      {
        title: 'Precision and Accuracy:',
        description: `Our face detection technology ensures high precision and accuracy, even in challenging environments. Whether it's surveillance, access control, or personalized user experiences, count on CyberDX for reliable results.`,
      },
      {
        title: 'Real-time Analysis:',
        description: `Experience real-time face detection and analysis, enabling swift decision-making processes. This feature is crucial for applications requiring instant identification and response.`,
      },
      {
        title: 'Advanced Security Measures:',
        description:
          'Elevate your security infrastructure with advanced facial recognition capabilities. CyberDX Intelligent Face Detection adds an extra layer of protection to your systems, ensuring secure access and authentication.',
      },
    ],
    whyChoose: {
      title: 'Why Choose CyberDX Intelligent Face Detection?',
      reasons: [
        {
          description:
            'Cutting-edge technology for accurate and fast face detection.',
        },
        {
          description:
            'Customizable solutions to meet the unique needs of your business.',
        },
        {
          description:
            'Seamless integration into various applications and platforms.',
        },
      ],
    },
    serviceSuport: {
      title: 'Industries We Serve',
      services: [
        {
          title: 'Security and Surveillance',
          description:
            'Enhance security measures with intelligent face detection for monitoring and identification.',
        },
        {
          title: 'Retail',
          description:
            'Elevate customer experiences through personalized interactions and analytics.',
        },
        {
          title: 'Healthcare',
          description:
            'Ensure patient security and streamline processes with reliable face recognition.',
        },
        {
          title: 'Access Control',
          description:
            'Strengthen access control systems for restricted areas with our advanced technology.',
        },
      ],
    },
  },
]

export const DATA_SOLUTION_RIGHT: ISolution[] = [
  {
    id: 3,
    title: 'CyberDX Intelligent Automation Platform',
    imageUrl: R.images.img_automation,
    isBottomBorderNone: true,
    titleDesciption: 'Empowering Efficiency Through Smart Automation',
    textLongDescription: `Transform your business processes with the cutting-edge "CyberDX Intelligent Automation Platform." Our platform is a comprehensive solution designed to enhance operational efficiency, reduce manual efforts, and drive innovation across your organization.`,
    iconUrl: 'https://storage.googleapis.com/cyberdx-storage/ic_automation.jpg',
    description:
      'A platform that helps businesses automate their business processes and improve efficiency.',
    listDescriptions: [
      {
        title: 'End-to-End Process Automation',
        description:
          'CyberDX Intelligent Automation Platform offers end-to-end automation of complex business processes. From data entry to decision-making workflows, streamline operations and minimize errors with seamless automation.',
      },
      {
        title: 'AI-Powered Decision Support',
        description:
          'Leverage the power of artificial intelligence (AI) for intelligent decision-making. Our platform integrates AI algorithms to analyze data, predict outcomes, and provide valuable insights, empowering your team to make informed choices.',
      },
      {
        title: 'Robotic Process Automation (RPA)',
        description:
          'Enhance productivity by automating repetitive tasks with RPA. Allow software robots to handle mundane, rule-based activities, freeing up human resources for more strategic and creative endeavors.',
      },
      {
        title: 'Scalable and Adaptable',
        description:
          'The platform is designed to grow with your business. Scale your automation efforts effortlessly, and adapt the platform to evolving business needs, ensuring long-term efficiency gains.',
      },
      {
        title: 'Real-time Analytics and Reporting',
        description:
          'Monitor, measure, and optimize processes with real-time analytics. Gain valuable insights into performance, identify bottlenecks, and make data-driven decisions for continuous improvement.',
      },
    ],
    serviceSuport: {
      title: 'Industries We Serve',
      services: [
        {
          title: 'Finance',
          description:
            'Automate complex financial processes, reduce errors, and ensure compliance with regulatory requirements.',
        },
        {
          title: 'Healthcare',
          description:
            'Streamline patient data management, appointment scheduling, and billing processes for improved healthcare delivery.',
        },
        {
          title: 'Manufacturing',
          description:
            'Optimize supply chain operations, inventory management, and production workflows for increased productivity.',
        },
        {
          title: 'Customer Service',
          description:
            'Automate customer interactions, inquiries, and issue resolution to enhance service delivery.',
        },
      ],
    },
    whyChoose: {
      title: 'Why Choose CyberDX Intelligent Automation Platform?',
      reasons: [
        {
          title: 'Holistic Automation',
          description:
            'Covering end-to-end processes with a unified platform for seamless automation.',
        },
        {
          title: 'Incorporating AI',
          description:
            'Harnessing the power of artificial intelligence for intelligent decision support.',
        },
        {
          title: 'Scalability',
          description:
            'Easily scale your automation initiatives to accommodate business growth.',
        },
        {
          title: 'Enhanced Productivity',
          description:
            'Reducing manual efforts and allowing your team to focus on strategic tasks.',
        },
      ],
    },
  },
  {
    id: 4,
    title: 'CyberDX AI-Chatbot',
    iconUrl: R.images.ic_chat_bot,
    imageUrl: R.images.img_chatbot,

    titleDesciption:
      'Revolutionizing Conversations with Intelligent Chatbot Technology',
    textLongDescription:
      'Welcome to the future of customer engagement with the "CyberDX AI-Chatbot." Our advanced chatbot solution is designed to transform how businesses interact with their customers, providing an intelligent, efficient, and personalized conversational experience.',
    description:
      'CyberDX AI-Chatbot is powered by a combination of natural language processing (NLP) and machine learning (ML). NLP allows the chatbot to understand the nuances of human language, while ML allows it to learn from its interactions with users and improve its responses over time.',
    listDescriptions: [
      {
        title: 'Natural Language Processing (NLP)',
        description:
          'Engage users in natural, human-like conversations. The AI-Chatbot leverages sophisticated Natural Language Processing to understand user intent, making interactions seamless and intuitive.',
      },
      {
        title: '24/7 Customer Support',
        description:
          'Ensure round-the-clock customer support with the AI-Chatbot. Answer queries, provide information, and assist customers anytime, enhancing user satisfaction and loyalty.',
      },
      {
        title: 'Multi-Channel Integration',
        description:
          'Seamlessly integrate the chatbot across various channels, including websites, messaging apps, and social media. Meet customers where they are and provide a consistent experience across platforms.',
      },
      {
        title: 'Intelligent Decision Making',
        description:
          'The AI-Chatbot is equipped with decision-making capabilities. It can provide product recommendations, guide users through processes, and assist with complex inquiries using artificial intelligence algorithms.',
      },
      {
        title: 'Continuous Learning',
        description:
          'Adapt and improve over time with continuous learning. The chatbot analyzes user interactions, learns from feedback, and evolves to provide more accurate and relevant responses.',
      },
    ],
    whyChoose: {
      title: 'Why Choose CyberDX AI-Chatbot?',
      reasons: [
        {
          title: 'Advanced NLP Technology',
          description:
            'Deliver human-like conversations with the power of Natural Language Processing.',
        },
        {
          title: 'Scalable and Flexible Integration',
          description:
            'Easily integrate the chatbot into various platforms and scale as your business grows.',
        },
        {
          title: 'Enhanced User Experience',
          description:
            'Provide personalized and efficient customer experiences, improving overall satisfaction.',
        },
      ],
    },
    serviceSuport: {
      title: 'Industries We Serve',
      services: [
        {
          title: 'E-Commerce',
          description:
            ' Enhance the shopping experience with personalized recommendations and instant assistance.',
        },
        {
          title: 'Healthcare',
          description:
            'Provide quick health-related information, appointment scheduling, and medication reminders.',
        },
        {
          title: 'Finance',
          description:
            'Assist with account inquiries, transaction details, and financial advice.',
        },
        {
          title: 'Hospitality',
          description:
            'Facilitate hotel bookings, answer travel queries, and provide local recommendations.',
        },
      ],
    },
    benefitOfSolutions: {
      title: 'Benefits for Businesses',
      benefits: [
        {
          title: 'Efficient Customer Interactions',
          description:
            'Streamline customer interactions, reduce response times, and handle multiple inquiries simultaneously, boosting operational efficiency.',
        },
        {
          title: 'Cost-Effective Support',
          description:
            'Provide cost-effective customer support by automating routine tasks, allowing human agents to focus on more complex issues.',
        },
        {
          title: 'Data-Driven Insights',
          description:
            'Gain valuable insights into customer preferences, behaviors, and frequently asked questions, empowering data-driven decision-making.',
        },
      ],
    },
  },
]

export const DATA_SOLUTIONS = [...DATA_SOLUTIONS_LEFT, ...DATA_SOLUTION_RIGHT]
