import { BaseButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput as CommonInput } from 'app/components/common/inputs/BaseInput'
import { PaddingMain } from 'app/constant'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PaddingMain.Desktop.defaultHz};
  min-width: 1280px !important;
`
export const TextTitle = styled.p`
  color: ${colors.white};
  font-size: 45px;
  font-weight: ${FONT_WEIGHT.bold};
`

export const AreaText = styled.div`
  padding: ${PADDING.md} 0px;
`

export const TextDescription = styled.p`
  color: ${colors.white};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`

export const FooterWrapper = styled(BaseRow)`
  margin-top: ${PaddingMain.Desktop.defaultHz};
  width: 100%;
  height: 550px;
  border: 0.5px solid ${colors.grayC4};
  background-color: rgba(0, 0, 0, 0.9);
`
export const LeftFooterWrapper = styled(BaseCol)`
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 0.5px solid ${colors.grayC4};
  height: 100%;
  color: ${colors.white};
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.semibold};
  padding: 2rem 2rem;
  text-align: center;
  background-image: url(https://storage.googleapis.com/cyberdx-storage/body1.jpg);
  background-mode: cover;
`
export const TextLeftFooterWrapper = styled(BaseCol)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  text-align: center;
  padding: ${PADDING.xxxxl};
  background-color: rgba(0, 0, 0, 0.2);
`

export const RightFooterWrapper = styled(BaseCol)`
  width: 65%;
  height: 100%;
  padding: 3rem 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xs};
`

export const ButtonSubmit = styled(BaseButtonVariant)`
  width: 100%;
  margin-top: ${PADDING.md};
`

export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.75rem;

  .ant-form-item-label > label {
    color: ${colors.grayC4};
    font-weight: ${FONT_WEIGHT.regular};
    font-size: ${FONT_SIZE.md};
    line-height: 1.5rem;
    padding: ${PADDING.xs} 0px;
  }

  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & .ant-form-item-label {
    padding: 0px;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`

export const FormInput = styled(CommonInput)`
  color: ${colors.white};
  background: transparent;

  height: 50px;
  width: 250px;

  & input.ant-input {
    background: transparent;
  }
`
