/* eslint-disable @typescript-eslint/no-explicit-any */
import FooterLayout from 'app/components/footer'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { useResponsive } from 'parkway-web-common'
import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Header } from '../../../header'
import MainContent from '../MainContent'
import { MainHeader } from '../MainHeader'
import * as S from './styles'

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true)
  const [siderCollapsed, setSiderCollapsed] = useState(false)
  const { isDesktop } = useResponsive()
  const location = useLocation()

  const toggleSider = () => setSiderCollapsed(!siderCollapsed)

  const ref = useRef<any>()

  useEffect(() => {
    setIsTwoColumnsLayout(
      [DASHBOARD_PATH].includes(location.pathname) && isDesktop,
    )
  }, [location.pathname, isDesktop])

  return (
    <S.LayoutMaster>
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={false}>
          <Header
            toggleSlider={toggleSider}
            isSliderOpened={!siderCollapsed}
            isTwoColumnsLayout={isTwoColumnsLayout}
          />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={false} ref={ref}>
          <div>
            <Outlet />
            <FooterLayout />
          </div>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  )
}

export default MainLayout
