import { BaseButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BLOG_PATH,
  CONTACT_PATH,
  DASHBOARD_PATH,
  SOLUTIONS_PATH,
  VISION_PATH,
} from 'app/components/router/route-path'
import { FONT_SIZE, PADDING } from 'parkway-web-common'
import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import * as S from '../styles'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import R from 'app/assets/R'

interface MobileHeaderProps {
  toggleSlider: () => void
  isSliderOpened: boolean
}

export const MobileHeader: React.FC<MobileHeaderProps> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const location = useLocation()

  const isFocusPath = (path: string) => {
    return isEqual(location.pathname, path)
  }

  return (
    <BaseCol>
      <BaseRow justify="space-between" align="middle">
        <BaseCol xl={5} xxl={5}>
          <S.ButtonPath
            style={{ paddingLeft: 0 }}
            onClick={() => navigate(DASHBOARD_PATH)}
            isFocus={true}
          >
            <BaseRow align={'middle'}>
              <BaseCol>
                <S.HomeText>{'CyberDX'}</S.HomeText>
              </BaseCol>
            </BaseRow>
          </S.ButtonPath>
        </BaseCol>
        <BaseCol xl={5}>
          <BaseButtonVariant
            padding={`${PADDING.xl} ${PADDING.xxl}`}
            fontSize={FONT_SIZE.xs}
            onClick={() => navigate(CONTACT_PATH)}
          >
            {'Get Started'}
          </BaseButtonVariant>
        </BaseCol>
      </BaseRow>
      <BaseCol>
        <BaseRow align={'middle'} justify={'start'}>
          <S.ButtonPath
            isFocus={isFocusPath(DASHBOARD_PATH)}
            onClick={() => navigate(DASHBOARD_PATH)}
          >
            {t(R.strings.home)}
          </S.ButtonPath>

          <S.ButtonPath
            onClick={() => navigate(SOLUTIONS_PATH)}
            isFocus={isFocusPath(SOLUTIONS_PATH)}
          >
            {t(R.strings.solutions)}
          </S.ButtonPath>

          <S.ButtonPath
            isFocus={isFocusPath(VISION_PATH)}
            onClick={() => navigate(VISION_PATH)}
          >
            {t(R.strings.vision)}
          </S.ButtonPath>

          <S.ButtonPath
            isFocus={isFocusPath(BLOG_PATH)}
            onClick={() => navigate(BLOG_PATH)}
          >
            {t(R.strings.blog)}
          </S.ButtonPath>
        </BaseRow>
      </BaseCol>
    </BaseCol>
  )
}
