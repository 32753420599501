import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BLOG_PATH,
  CONTACT_PATH,
  DASHBOARD_PATH,
  SOLUTIONS_PATH,
  VISION_PATH,
} from 'app/components/router/route-path'
import { PaddingMain } from 'app/constant'
import { isEqual } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import * as S from '../styles'
import { BaseButtonVariant } from 'app/components/common/BaseButtonVariant'
import { FONT_SIZE, PADDING } from 'parkway-web-common'

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const location = useLocation()

  const isFocusPath = (path: string) => {
    return isEqual(location.pathname, path)
  }

  return (
    <BaseRow
      justify="space-between"
      align="middle"
      style={{ paddingLeft: PaddingMain.Desktop.defaultHz }}
    >
      <BaseCol xl={5} xxl={5}>
        <S.ButtonPath
          style={{ paddingLeft: 0 }}
          onClick={() => navigate(DASHBOARD_PATH)}
          isFocus={true}
        >
          <BaseRow align={'middle'}>
            <BaseCol>
              <S.HomeText>{'CyberDX'}</S.HomeText>
            </BaseCol>
          </BaseRow>
        </S.ButtonPath>
      </BaseCol>

      <S.ProfileColumn xl={10} xxl={9}>
        <BaseRow align={'middle'} justify={'space-between'}>
          <BaseCol>
            <BaseRow gutter={10} align={'middle'}>
              <BaseCol>
                <S.ButtonPath
                  isFocus={isFocusPath(DASHBOARD_PATH)}
                  onClick={() => navigate(DASHBOARD_PATH)}
                >
                  {t(R.strings.home)}
                </S.ButtonPath>
              </BaseCol>
              <BaseCol>
                <S.ButtonPath
                  onClick={() => navigate(SOLUTIONS_PATH)}
                  isFocus={isFocusPath(SOLUTIONS_PATH)}
                >
                  {t(R.strings.solutions)}
                </S.ButtonPath>
              </BaseCol>
              <BaseCol>
                <S.ButtonPath
                  isFocus={isFocusPath(VISION_PATH)}
                  onClick={() => navigate(VISION_PATH)}
                >
                  {t(R.strings.vision)}
                </S.ButtonPath>
              </BaseCol>
              <BaseCol>
                <S.ButtonPath
                  isFocus={isFocusPath(BLOG_PATH)}
                  onClick={() => navigate(BLOG_PATH)}
                >
                  {t(R.strings.blog)}
                </S.ButtonPath>
              </BaseCol>
            </BaseRow>
          </BaseCol>
          <BaseCol>
            <BaseButtonVariant
              padding={`${PADDING.xl} ${PADDING.xxl}`}
              fontSize={FONT_SIZE.xs}
              onClick={() => navigate(CONTACT_PATH)}
            >
              {'Get Started'}
            </BaseButtonVariant>
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  )
}
