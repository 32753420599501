export default {
  see_more: 'Xem thêm',
  find: 'Tìm kiếm',
  address: 'Địa chỉ',
  language: 'Ngôn ngữ',
  vietnamese: 'Tiếng việt',
  english: 'English',
  foreign_language: 'Ngoại ngữ',
  home: 'Home',
  phone: 'Số điện thoại',
  email: 'Email',
  send: 'Gửi',
  next: 'Tiếp theo',
  back: 'Quay lại',
  send_code: 'Gửi mã',
  login: 'Đăng nhập',
  user_name: 'Tên đăng nhập',
  password: 'Mật khẩu',
  login_success: 'Đăng nhập thành công',
  forgot_password: 'Quên mật khẩu',
  require_field: 'Vui lòng nhập "{{field}}", đây là trường bắt buộc',
  minimum_field: 'Vui lòng nhập tối thiểu {{count}} kí tự',
  maximum_field: 'Vui lòng nhập tối đa {{count}} kí tự',
  register: 'Đăng kí',
  confirm_password: 'Xác nhận mật khẩu',
  verify_by_phone: 'Xác thực bằng số điện thoại',
  verify_by_email: 'Xác thực bằng số email',
  code_verify: 'Mã xác thực',
  message_verify_phone:
    'Bạn vui lòng kiểm tra trong tin nhắn Zalo của số điện thoại {{phone}} để lấy mã xác thực.',
  message_verify_email:
    'Bạn vui lòng kiểm tra trong hộp thư (bao gồm cả thư rác) của email "{{email}}" để lấy mã xác thực.',
  re_send_verify_code: 'Gửi lại mã xác thực',
  resend: 'Gửi lại',
  time_resend_verify_code: 'Gửi lại sau {{timer}} giây',
  verify: 'Xác thực',
  logout: 'Đăng xuất',
  full_name: 'Họ và tên',
  regex_name: 'Tên cần đúng định dạng',
  regex_user_name: 'User name cần đúng định dạng (Không bao gồm dấu "cách")',
  using_by: 'Được sử dụng bởi',
  require_email: 'Vui lòng nhập email',
  require_contact_name: 'Vui lòng nhập tên người liên hệ',
  regex_email: 'Vui lòng nhập đúng định dạng email (ex: abc@gmail.com)',
  regex_phone: 'Vui lòng nhập đúng định dạng số điện thoại (ex: 0965259441)',
  send_contact_success:
    'Đã gửi thông tin liên hệ thành công, cảm ơn quý khách.',
  please_enter_correct_format: 'Vui lòng  định dạng',
  collapse: 'Thu gọn',
  last_time_update: 'Cập nhật gần nhất',
  service: 'Dịch vụ',
  month: 'Tháng',
  import_file: 'Import file',
  cancel: 'Hủy',
  export_excel: 'Export excel',
  download_example_import_file: '',
  solutions: 'Solutions',
  vision: 'Vision',
  programs: 'Programs',
  blog: 'Blog',
  coming_soon: 'Comming soon',
}
