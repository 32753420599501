import MainLayout from 'app/components/layouts/main/MainLayout'
import RequireAuth from 'app/components/router/require-auth-router'
import { withLoading } from 'app/hocs/with-loading.hoc'
import { ContactPage } from 'app/page/menu/contact'
import { DashboardPage } from 'app/page/menu/home'
import { SolutionsPage } from 'app/page/menu/solutions'
import { VisionPage } from 'app/page/menu/vision'
import ComingSoonPage from 'app/page/other/coming-soon'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  BLOG_PATH,
  CONTACT_PATH,
  DASHBOARD_PATH,
  SOLUTIONS_DETAIL_PATH,
  SOLUTIONS_PATH,
  VISION_PATH,
} from './route-path'
import { SolutionsDetailPage } from 'app/page/menu/solutions-detail'

const Dashboard = withLoading(DashboardPage)
const Solutions = withLoading(SolutionsPage)
const SolutionsDetail = withLoading(SolutionsDetailPage)
const Vision = withLoading(VisionPage)
const Blog = withLoading(ComingSoonPage)
const Contact = withLoading(ContactPage)

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  )

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path={SOLUTIONS_PATH} element={<Solutions />} />
          <Route path={SOLUTIONS_DETAIL_PATH} element={<SolutionsDetail />} />
          <Route path={VISION_PATH} element={<Vision />} />
          <Route path={BLOG_PATH} element={<Blog />} />
          <Route path={CONTACT_PATH} element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
