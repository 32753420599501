import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { PaddingMain } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PaddingMain.Desktop.defaultHz} ${PADDING.md};
`
export const ListDataWrapper = styled(BaseCol)``
export const TextTitle = styled.p`
  color: ${colors.white};
  font-size: 50px;
  padding-bottom: ${PADDING.xxxl};
`

export const AreaText = styled.div`
  padding: ${PADDING.md} 0px;
`

export const OurService = styled.span`
  color: ${colors.white};
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.medium};
`

export const ItemWrapper = styled(BaseCol)<{ isBottomBorderNone?: boolean }>`
  width: 100%;
  border: 0.5px solid ${colors.grayC4};
  border-bottom: ${props =>
    props.isBottomBorderNone ? '0px' : `0.5px solid ${colors.grayC4}`};
`

export const LeftItemWrapper = styled(BaseCol)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${PADDING.xxxl};
  background-color: rgba(0, 0, 0, 0.9);
  .ant-image {
    width: 60% !important;
  }
`

export const IconItem = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS};
`

export const RightItemWrapper = styled(BaseCol)`
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.9);
`

export const TitleRightItem = styled.span`
  color: ${colors.white};
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const DescriptionRightItem = styled.span`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`
export const FooterWrapper = styled(BaseRow)`
  width: 100%;
  border: 0.5px solid ${colors.grayC4};
  border-top: 0px;
`
export const LeftFooterWrapper = styled(BaseCol)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 15rem;
  color: ${colors.white};
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.medium};
  padding: 2rem 2rem;
  text-align: center;
  background-image: url(https://storage.googleapis.com/cyberdx-storage/6.jpg);
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.9);
`
export const TextLeftFooterWrapper = styled(BaseCol)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.medium};
  text-align: center;
  padding: ${PADDING.md} 0px;
`

export const RightFooterWrapper = styled(BaseCol)`
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xs};
  background-color: rgba(0, 0, 0, 0.9);
`
