import { BaseCol } from 'app/components/common/BaseCol'
import { useInView } from 'react-intersection-observer'
import * as S from './styles'
import * as STablet from './styles.tablet'
import { useResponsive } from 'parkway-web-common'

const TakePriceHome = () => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const { isDesktop } = useResponsive()
  if (isDesktop) {
    return (
      <S.RootWrapper ref={ref}>
        <S.Tile className={inView ? 'text-opacity-left-to' : ''}>
          {'We Take Pride in Our Numbers'}
        </S.Tile>
        <S.ListWrapper gutter={20} justify={'space-between'} wrap={false}>
          <S.BaseColItem xl={4}>
            <S.ItemWrapper>
              <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
                <S.TextValueItem>{5}</S.TextValueItem>
              </BaseCol>
              <S.TextDescriptionItem
                className={inView ? 'text-opacity-left-to' : ''}
              >
                {'Years of Experience'}
              </S.TextDescriptionItem>
              <S.BottomLine className={inView ? 'text-opacity-left-to' : ''} />
            </S.ItemWrapper>
          </S.BaseColItem>
          <S.BaseColItem xl={4}>
            <S.ItemWrapper>
              <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
                <S.TextValueItem>{'5K'}</S.TextValueItem>
              </BaseCol>
              <S.TextDescriptionItem
                className={inView ? 'text-opacity-left-to' : ''}
              >
                {'Business Partners'}
              </S.TextDescriptionItem>
              <S.BottomLine className={inView ? 'text-opacity-left-to' : ''} />
            </S.ItemWrapper>
          </S.BaseColItem>

          <S.BaseColItem xl={4}>
            <S.ItemWrapper>
              <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
                <S.TextValueItem>{'1M'}</S.TextValueItem>
              </BaseCol>
              <S.TextDescriptionItem
                className={inView ? 'text-opacity-left-to' : ''}
              >
                {'Products Installed'}
              </S.TextDescriptionItem>
              <S.BottomLine className={inView ? 'text-opacity-left-to' : ''} />
            </S.ItemWrapper>
          </S.BaseColItem>

          <S.BaseColItem xl={4}>
            <S.ItemWrapper>
              <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
                <S.TextValueItem>{'5'}</S.TextValueItem>
              </BaseCol>
              <S.TextDescriptionItem
                className={inView ? 'text-opacity-left-to' : ''}
              >
                {'Countries World Wide'}
              </S.TextDescriptionItem>
              <S.BottomLine className={inView ? 'text-opacity-left-to' : ''} />
            </S.ItemWrapper>
          </S.BaseColItem>
        </S.ListWrapper>
      </S.RootWrapper>
    )
  }
  return (
    <STablet.RootWrapper ref={ref}>
      <STablet.Tile className={inView ? 'text-opacity-left-to' : ''}>
        {'We Take Pride in Our Numbers'}
      </STablet.Tile>
      <STablet.ListWrapper gutter={[30, 30]} justify={'center'}>
        <STablet.BaseColItem>
          <STablet.ItemWrapper>
            <STablet.TextValueItem
              className={inView ? 'text-opacity-left-to' : ''}
            >
              {5}
            </STablet.TextValueItem>

            <STablet.TextDescriptionItem
              className={inView ? 'text-opacity-left-to' : ''}
            >
              {'Years of Experience'}
            </STablet.TextDescriptionItem>
            <STablet.BottomLine
              className={inView ? 'text-opacity-left-to' : ''}
            />
          </STablet.ItemWrapper>
        </STablet.BaseColItem>
        <STablet.BaseColItem>
          <STablet.ItemWrapper>
            <STablet.TextValueItem
              className={inView ? 'text-opacity-left-to' : ''}
            >
              {'5K'}
            </STablet.TextValueItem>
            <STablet.TextDescriptionItem
              className={inView ? 'text-opacity-left-to' : ''}
            >
              {'Business Partners'}
            </STablet.TextDescriptionItem>
            <STablet.BottomLine
              className={inView ? 'text-opacity-left-to' : ''}
            />
          </STablet.ItemWrapper>
        </STablet.BaseColItem>

        <STablet.BaseColItem>
          <STablet.ItemWrapper>
            <STablet.TextValueItem
              className={inView ? 'text-opacity-right-to' : ''}
            >
              {'1M'}
            </STablet.TextValueItem>

            <STablet.TextDescriptionItem
              className={inView ? 'text-opacity-left-to' : ''}
            >
              {'Products Installed'}
            </STablet.TextDescriptionItem>
            <STablet.BottomLine
              className={inView ? 'text-opacity-left-to' : ''}
            />
          </STablet.ItemWrapper>
        </STablet.BaseColItem>

        <STablet.BaseColItem>
          <STablet.ItemWrapper>
            <STablet.TextValueItem
              className={inView ? 'text-opacity-right-to' : ''}
            >
              {'5'}
            </STablet.TextValueItem>

            <STablet.TextDescriptionItem
              className={inView ? 'text-opacity-left-to' : ''}
            >
              {'Countries World Wide'}
            </STablet.TextDescriptionItem>
            <STablet.BottomLine
              className={inView ? 'text-opacity-left-to' : ''}
            />
          </STablet.ItemWrapper>
        </STablet.BaseColItem>
      </STablet.ListWrapper>
    </STablet.RootWrapper>
  )
}

export default TakePriceHome
