import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { PaddingMain } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PADDING.md};
`

export const RootHeader = styled(BaseRow)`
  padding: ${PADDING.xl};
`
export const TextTitle = styled.p`
  color: ${colors.white};
  font-size: 50px;
`

export const AreaText = styled.div`
  padding: ${PADDING.md} 0px;
`

export const ContentWrapper = styled(BaseRow)`
  margin-top: 5rem;
`

export const LeftWrapper = styled(BaseCol)`
  width: 100% !important;
  padding: ${PADDING.md};
  background-color: rgba(0, 0, 0, 0.8);
  padding-bottom: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`

export const ImageSolution = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS};
`

export const TextDescriptionTotal = styled.div`
  color: ${colors.white};
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.medium};
`

export const TextLongDescription = styled.div`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const TextTitleService = styled.div`
  color: ${colors.white};
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.medium};
`

export const ItemOtherService = styled(BaseRow)`
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.md};
  background-color: ${colors.white};
  margin-bottom: ${PADDING.md};
  cursor: pointer;
  color: ${colors.gray59};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
  transition: all 1.5s ease;
  &:hover {
    color: var(--red-orange-color);
    transition: all 1.5s ease;
  }
`

export const TextOtherService = styled.div``

export const Divider = styled(BaseDivider)`
  background-color: ${colors.grayC4};
`

export const WhyChooseWrapper = styled(BaseCol)``

export const TitleWhy = styled.div`
  color: var(--red-orange-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`
export const ItemReasonWhy = styled(BaseRow)`
  background-color: ${colors.white};
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xs};
  margin-top: 10px;
`

export const LeftPointReasonWhy = styled.div`
  background-color: var(--red-orange-color);
  width: 7px;
  height: 7px;
  border-radius: ${BORDER_RADIUS};
`
export const TextTitleOfReason = styled.span`
  color: ${colors.white};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`

export const TextDescriptionOfReason = styled.span`
  color: ${colors.black};
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const TextTitleTotal = styled.div`
  color: var(--red-orange-color);
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const ServiceSuportWrapper = styled(BaseCol)``

export const TitleServiceSuport = styled.div`
  color: var(--red-orange-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`
export const ItemReasonServiceSuport = styled(BaseRow)`
  background-color: var(--red-orange-color);
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xs};
  margin-top: 10px;
`

export const TextTitleOfService = styled.span`
  color: ${colors.white};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`

export const TextDescriptionOfService = styled.span`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const BenefitWrapper = styled(BaseCol)``

export const TitleBenefit = styled.div`
  color: ${colors.white};
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.medium};
`
export const ItemReasonBenefit = styled(BaseRow)`
  background-color: ${colors?.white};
  border-radius: ${BORDER_RADIUS};
  padding: ${PaddingMain.Desktop.defaultHz} ${PADDING.xs};
  margin-top: 10px;
`

export const TextTitleOfBenefit = styled.span`
  color: var(--red-orange-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`
