import { BaseBadgeProps } from 'app/components/common/BaseBadge'
import { NotificationType } from 'app/components/common/BaseNotification'
import { Severity } from 'parkway-web-common'
import { MutableRefObject, useEffect, useRef } from 'react'

interface NotificationSeverity {
  id: number
  name: NotificationType
}

export const defineColorBySeverity = (
  severity: NotificationType | undefined,
  rgb = false,
): string => {
  const postfix = rgb ? 'rgb-color' : 'color'
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`
    case 'info':
    default:
      return `var(--primary-${postfix})`
  }
}

export const notificationsSeverities: NotificationSeverity[] = [
  { id: 0, name: 'info' },
  { id: 1, name: 'success' },
  { id: 2, name: 'warning' },
  { id: 3, name: 'error' },
  { id: 4, name: 'mention' },
]

export const normalizeProp = (
  prop: string | number | [number, number],
): string =>
  typeof prop === 'number'
    ? `${prop}px`
    : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString()

export const mapBadgeStatus = (status: BaseBadgeProps['status']): Severity => {
  if (!status || status === 'default' || status === 'processing') {
    return 'info'
  }

  return status
}

interface UseMountedReturnVal {
  isMounted: MutableRefObject<boolean>
}

export const useMounted = (): UseMountedReturnVal => {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return { isMounted }
}
