import { BaseCol } from 'app/components/common/BaseCol'
import React from 'react'
import DataHome from './components/data'
import HeaderHome from './components/header'
import OurPartnerHome from './components/partner'
import TakePriceHome from './components/take-pride'
import VelocityHome from './components/velocity'
import * as S from './styles'
import { useResponsive } from 'parkway-web-common'

const DashboardContainer: React.FC = () => {
  const { isDesktop } = useResponsive()
  const desktopLayout = (
    <BaseCol>
      <HeaderHome />
      <S.ContentWrapper isTabletDown={!isDesktop}>
        <DataHome />
        <VelocityHome />
      </S.ContentWrapper>
      <TakePriceHome />
      <S.ContentWrapper isTabletDown={!isDesktop}>
        <OurPartnerHome />
      </S.ContentWrapper>
    </BaseCol>
  )
  return <S.ScrollWrapper>{desktopLayout}</S.ScrollWrapper>
}

export default DashboardContainer
