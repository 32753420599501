import { memo } from 'react'
import { isEqual } from 'lodash'
import DashboardContainer from 'app/containers/Dashboard'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const DashboardCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.home)}</PageTitle>
      <DashboardContainer />
    </>
  )
}
export const DashboardPage = memo(DashboardCpn, isEqual)
