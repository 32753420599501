import { BaseCol } from 'app/components/common/BaseCol'
import { PaddingMain } from 'app/constant'
import { FONT_SIZE, colors } from 'parkway-web-common'
import styled from 'styled-components'

const ComingSoonContainer = () => {
  return (
    <RootWrapper>
      <TextComingSoon>{'Coming soon'}</TextComingSoon>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseCol)`
  padding: ${PaddingMain.Desktop.defaultHz};
  min-height: 70vh;
`

export const TextComingSoon = styled.span`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xxl};
`
export default ComingSoonContainer
