
function strings(){
    return{
        see_more: "see_more",
        find: "find",
        address: "address",
        language: "language",
        vietnamese: "vietnamese",
        english: "english",
        foreign_language: "foreign_language",
        home: "home",
        phone: "phone",
        email: "email",
        send: "send",
        next: "next",
        back: "back",
        send_code: "send_code",
        login: "login",
        user_name: "user_name",
        password: "password",
        login_success: "login_success",
        forgot_password: "forgot_password",
        require_field: "require_field",
        minimum_field: "minimum_field",
        maximum_field: "maximum_field",
        register: "register",
        confirm_password: "confirm_password",
        verify_by_phone: "verify_by_phone",
        verify_by_email: "verify_by_email",
        code_verify: "code_verify",
        message_verify_phone: "message_verify_phone",
        message_verify_email: "message_verify_email",
        re_send_verify_code: "re_send_verify_code",
        resend: "resend",
        time_resend_verify_code: "time_resend_verify_code",
        verify: "verify",
        logout: "logout",
        full_name: "full_name",
        regex_name: "regex_name",
        regex_user_name: "regex_user_name",
        using_by: "using_by",
        require_email: "require_email",
        require_contact_name: "require_contact_name",
        regex_email: "regex_email",
        regex_phone: "regex_phone",
        send_contact_success: "send_contact_success",
        please_enter_correct_format: "please_enter_correct_format",
        collapse: "collapse",
        last_time_update: "last_time_update",
        service: "service",
        month: "month",
        import_file: "import_file",
        cancel: "cancel",
        export_excel: "export_excel",
        download_example_import_file: "download_example_import_file",
        solutions: "solutions",
        vision: "vision",
        programs: "programs",
        blog: "blog",
        coming_soon: "coming_soon"
}}
export default strings
        