import R from 'app/assets/R'
import { BaseImage } from 'app/components/common/BaseImage'
import { ISolution } from 'app/containers/SolutionsDetail'
import * as S from './styles'
import { useNavigate } from 'react-router'
import { SOLUTIONS_DETAIL_PATH } from 'app/components/router/route-path'

export const ItemDataAbsoluteComponent = ({
  inView,
  item,
}: {
  inView: boolean
  item: ISolution
}) => {
  const navigate = useNavigate()
  const onPress = () => {
    navigate(SOLUTIONS_DETAIL_PATH, {
      state: item,
    })
  }
  return (
    <S.ItemRightSide onClick={onPress}>
      <BaseImage
        className={inView ? 'text-opacity-left-to' : ''}
        style={{ width: 32, height: 32 }}
        src={item?.iconUrl ?? R.images.ic_parkway}
        preview={false}
      />
      <div>
        <S.TextTitleItemPosition
          className={inView ? 'text-opacity-left-to' : ''}
        >
          {item.title}
        </S.TextTitleItemPosition>

        <S.TextDescriptionItemPosition
          className={inView ? 'text-opacity-left-to' : ''}
        >
          {item?.description ?? ''}
        </S.TextDescriptionItemPosition>
      </div>
      <S.BottomLineItem />
    </S.ItemRightSide>
  )
}
