import React from 'react'
import styled from 'styled-components'
import { BaseSpin } from '../BaseSpin'

interface LoadingProps {
  size?: string
  color?: string
}

export const Loading: React.FC<LoadingProps> = () => {
  return (
    <SpinnerContainer>
      <BaseSpin size={'large'} />
    </SpinnerContainer>
  )
}

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
