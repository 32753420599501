/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { DATA_SOLUTIONS } from 'app/constant'
import { useResponsive } from 'parkway-web-common'
import { useEffect, useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useLocation, useNavigate } from 'react-router'
import * as S from './styles.desktop'
import * as STablet from './styles.tablet'
import { isEqual } from 'lodash'
import { SOLUTIONS_DETAIL_PATH } from 'app/components/router/route-path'

interface IDetailDescription {
  title?: string
  description?: string
  imageUrls?: string[]
}

export interface ISolution {
  id?: number | string
  title: string
  isBottomBorderNone?: boolean
  inView?: boolean
  description?: string
  iconUrl?: string
  imageUrl?: string
  textLongDescription?: string
  titleDesciption?: string
  listDescriptions?: IDetailDescription[]
  whyChoose?: {
    title?: string
    reasons?: IDetailDescription[]
  }
  serviceSuport?: {
    title?: string
    services?: IDetailDescription[]
  }
  benefitOfSolutions?: {
    title?: string
    benefits?: IDetailDescription[]
  }
}

export const TextFooter =
  'Để khám phá thêm về cách chúng tôi có thể hỗ trợ doanh nghiệp của bạn, vui lòng liên hệ với chúng tôi ngay hôm nay. CyberDX - Nơi chuyển đổi ý tưởng thành hiện thực!'

export const SolutionDetail = () => {
  const { state } = useLocation()
  const { isDesktop } = useResponsive()
  const navigate = useNavigate()
  const inViewAll = useInView({ triggerOnce: true })
  const inViewDescription = useInView({ triggerOnce: true })
  const inViewBenefit = useInView({ triggerOnce: true })
  const inViewService = useInView({ triggerOnce: true })
  const inViewReason = useInView({ triggerOnce: true })

  const item: ISolution = useMemo(() => {
    return state
  }, [state])

  const ref = useRef<any>()

  useEffect(() => {
    const timer = setTimeout(() => {
      ref?.current.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    return () => clearTimeout(timer)
  }, [location.pathname])

  if (isDesktop) {
    return (
      <S.RootWrapper ref={ref}>
        <S.RootHeader justify={'center'}>
          <BaseCol>
            <S.AreaText>
              <S.TextTitle>{item?.title}</S.TextTitle>
            </S.AreaText>
            {item?.titleDesciption && (
              <S.AreaText>
                <S.TextTitleTotal>
                  {item?.titleDesciption ?? ''}
                </S.TextTitleTotal>
              </S.AreaText>
            )}
          </BaseCol>
        </S.RootHeader>
        <S.ContentWrapper gutter={[16, 16]} justify={'space-between'}>
          <S.LeftWrapper>
            <S.ImageSolution
              loading="eager"
              src={item?.imageUrl}
              preview={false}
            />
            {item?.titleDesciption && (
              <S.AreaText>
                <S.TextTitleTotal>
                  {item?.titleDesciption ?? ''}
                </S.TextTitleTotal>
              </S.AreaText>
            )}
            {item?.description && (
              <S.AreaText>
                <S.TextDescriptionTotal>
                  {item?.description ?? ''}
                </S.TextDescriptionTotal>
              </S.AreaText>
            )}
            {item?.textLongDescription && (
              <S.AreaText>
                <S.TextLongDescription>
                  {item?.textLongDescription ?? ''}
                </S.TextLongDescription>
              </S.AreaText>
            )}
            <S.Divider />
            <div ref={inViewDescription.ref}>
              {item?.listDescriptions &&
                item?.listDescriptions?.map((item, index) => {
                  return (
                    <BaseCol key={index}>
                      <BaseCol
                        className={`${
                          inViewDescription.inView ? 'text-opacity-left-to' : ''
                        }`}
                      >
                        <S.TextDescriptionTotal>
                          {item?.title ?? ''}
                        </S.TextDescriptionTotal>
                      </BaseCol>
                      <S.AreaText
                        className={`${
                          inViewDescription.inView
                            ? 'text-opacity-right-to'
                            : ''
                        }`}
                      >
                        <S.TextLongDescription>
                          {item?.description ?? ''}
                        </S.TextLongDescription>
                      </S.AreaText>
                      {item?.imageUrls?.length && (
                        <BaseRow
                          gutter={[20, 20]}
                          justify={'center'}
                          align={'middle'}
                        >
                          {item?.imageUrls?.map((url, idx) => {
                            return (
                              <BaseCol
                                key={idx}
                                xl={6}
                                className={`${
                                  inViewDescription.inView
                                    ? 'text-opacity-left-to'
                                    : ''
                                }`}
                              >
                                <S.AreaText>
                                  <S.ImageSolution
                                    src={url ?? R.images.playstore_icon}
                                  />
                                </S.AreaText>
                              </BaseCol>
                            )
                          })}
                        </BaseRow>
                      )}
                    </BaseCol>
                  )
                })}
            </div>

            {item?.benefitOfSolutions && (
              <S.BenefitWrapper ref={inViewBenefit.ref}>
                <S.Divider />
                <S.TitleBenefit
                  className={`${
                    inViewBenefit.inView ? 'text-opacity-left-to' : ''
                  }`}
                >
                  {item?.benefitOfSolutions?.title}
                </S.TitleBenefit>
                <S.AreaText>
                  <BaseRow gutter={[16, 16]} align={'middle'}>
                    {item?.benefitOfSolutions?.benefits?.map((item, idx) => {
                      return (
                        <BaseCol xl={6}>
                          <S.ItemReasonBenefit
                            key={idx}
                            gutter={[10, 10]}
                            justify={'center'}
                            align={'middle'}
                          >
                            <S.TextTitleOfBenefit
                              className={`${
                                inViewBenefit.inView ? 'text-opacity-up-to' : ''
                              }`}
                            >
                              {item?.title ? `${item?.title}` : ''}
                            </S.TextTitleOfBenefit>
                          </S.ItemReasonBenefit>
                        </BaseCol>
                      )
                    })}
                  </BaseRow>
                </S.AreaText>
              </S.BenefitWrapper>
            )}

            <S.Divider />

            {item?.serviceSuport && (
              <S.ServiceSuportWrapper ref={inViewService.ref}>
                <S.TitleServiceSuport
                  className={`${
                    inViewBenefit.inView ? 'text-opacity-right-to' : ''
                  }`}
                >
                  {item?.serviceSuport?.title}
                </S.TitleServiceSuport>
                <S.AreaText>
                  {item?.serviceSuport?.services?.map((item, idx) => {
                    return (
                      <S.ItemReasonServiceSuport
                        key={idx}
                        gutter={[10, 10]}
                        align={'middle'}
                      >
                        <BaseCol
                          className={`${
                            inViewService.inView ? 'text-opacity-left-to' : ''
                          }`}
                        >
                          <S.TextTitleOfService>
                            {item?.title ? `${item?.title}:` : ''}
                          </S.TextTitleOfService>
                        </BaseCol>
                        <BaseCol
                          className={`${
                            inViewService.inView ? 'text-opacity-right-to' : ''
                          }`}
                        >
                          <S.TextDescriptionOfService>
                            {item?.description ?? ''}
                          </S.TextDescriptionOfService>
                        </BaseCol>
                      </S.ItemReasonServiceSuport>
                    )
                  })}
                </S.AreaText>
              </S.ServiceSuportWrapper>
            )}

            <S.Divider />
            {item?.whyChoose && (
              <S.WhyChooseWrapper ref={inViewReason.ref}>
                <S.TitleWhy
                  className={`${
                    inViewReason.inView ? 'text-opacity-right-to' : ''
                  }`}
                >
                  {item?.whyChoose?.title}
                </S.TitleWhy>
                <S.AreaText>
                  <BaseRow gutter={[16, 16]} align={'middle'}>
                    {item?.whyChoose?.reasons?.map((item, idx) => {
                      return (
                        <BaseCol xl={6}>
                          <S.ItemReasonWhy
                            key={idx}
                            gutter={[10, 10]}
                            align={'middle'}
                          >
                            <BaseCol>
                              <BaseRow
                                gutter={[10, 10]}
                                align={'middle'}
                                wrap={false}
                              >
                                <S.LeftPointReasonWhy
                                  className={`${
                                    inViewReason.inView
                                      ? 'text-opacity-left-to'
                                      : ''
                                  }`}
                                />
                                <BaseCol
                                  className={`${
                                    inViewReason.inView
                                      ? 'text-opacity-right-to'
                                      : ''
                                  }`}
                                >
                                  <S.TextTitleOfReason>
                                    {item?.title ? `${item?.title}:` : ''}
                                  </S.TextTitleOfReason>
                                </BaseCol>
                              </BaseRow>
                            </BaseCol>
                            <BaseCol
                              className={`${
                                inViewReason.inView
                                  ? 'text-opacity-left-to'
                                  : ''
                              }`}
                            >
                              <S.TextDescriptionOfReason>
                                {item?.description ?? ''}
                              </S.TextDescriptionOfReason>
                            </BaseCol>
                          </S.ItemReasonWhy>
                        </BaseCol>
                      )
                    })}
                  </BaseRow>
                </S.AreaText>
              </S.WhyChooseWrapper>
            )}

            <S.TextLongDescription
              className={`${inViewReason.inView ? 'text-opacity-up-to' : ''}`}
            >
              {TextFooter}
            </S.TextLongDescription>
          </S.LeftWrapper>
          <S.RightWrapper>
            <S.TextTitleService>{'Other solutions'}</S.TextTitleService>
            <S.Divider />
            {DATA_SOLUTIONS?.filter(itm => !isEqual(itm?.id, item?.id))?.map(
              (itemOtherService, index) => {
                return (
                  <S.ItemOtherService
                    key={index}
                    className={`${
                      inViewAll.inView ? 'text-opacity-right-to' : ''
                    }`}
                    onClick={() =>
                      navigate(SOLUTIONS_DETAIL_PATH, {
                        state: itemOtherService,
                      })
                    }
                  >
                    <S.TextOtherService>
                      {itemOtherService?.title}
                    </S.TextOtherService>
                    <div className="child-service">
                      <S.Divider />
                      {itemOtherService?.listDescriptions?.length && (
                        <BaseCol>
                          {itemOtherService?.listDescriptions?.map(
                            (des, idx) => {
                              return (
                                <BaseCol key={idx}>
                                  <S.AreaText>
                                    <S.TextTitleOfBenefit>
                                      {des?.title}
                                    </S.TextTitleOfBenefit>
                                  </S.AreaText>
                                </BaseCol>
                              )
                            },
                          )}
                        </BaseCol>
                      )}
                    </div>
                  </S.ItemOtherService>
                )
              },
            )}
          </S.RightWrapper>
        </S.ContentWrapper>
      </S.RootWrapper>
    )
  }

  return (
    <STablet.RootWrapper ref={ref}>
      <STablet.RootHeader justify={'center'}>
        <STablet.AreaText>
          <STablet.TextTitle>{item?.title}</STablet.TextTitle>
        </STablet.AreaText>
      </STablet.RootHeader>
      <STablet.ContentWrapper
        gutter={[16, 16]}
        justify={'space-between'}
        ref={inViewAll.ref}
      >
        <STablet.LeftWrapper>
          <STablet.ImageSolution
            loading="eager"
            src={item?.imageUrl}
            preview={false}
          />
          {item?.titleDesciption && (
            <STablet.AreaText>
              <STablet.TextTitleTotal>
                {item?.titleDesciption ?? ''}
              </STablet.TextTitleTotal>
            </STablet.AreaText>
          )}
          {item?.description && (
            <STablet.AreaText>
              <STablet.TextDescriptionTotal>
                {item?.description ?? ''}
              </STablet.TextDescriptionTotal>
            </STablet.AreaText>
          )}
          {item?.textLongDescription && (
            <STablet.AreaText>
              <STablet.TextLongDescription>
                {item?.textLongDescription ?? ''}
              </STablet.TextLongDescription>
            </STablet.AreaText>
          )}

          <div ref={inViewDescription.ref}>
            {item?.listDescriptions &&
              item?.listDescriptions?.map((item, index) => {
                return (
                  <BaseCol key={index}>
                    <STablet.Divider />
                    <BaseCol
                      className={`${
                        inViewDescription.inView ? 'text-opacity-left-to' : ''
                      }`}
                    >
                      <STablet.TextDescriptionTotal>
                        {item?.title ?? ''}
                      </STablet.TextDescriptionTotal>
                    </BaseCol>
                    <STablet.AreaText
                      className={`${
                        inViewDescription.inView ? 'text-opacity-right-to' : ''
                      }`}
                    >
                      <STablet.TextLongDescription>
                        {item?.description ?? ''}
                      </STablet.TextLongDescription>
                    </STablet.AreaText>
                    {item?.imageUrls?.length && (
                      <BaseRow
                        gutter={[20, 20]}
                        justify={'center'}
                        align={'middle'}
                      >
                        {item?.imageUrls?.map((url, idx) => {
                          return (
                            <BaseCol
                              key={idx}
                              xl={6}
                              className={`${
                                inViewDescription.inView
                                  ? 'text-opacity-left-to'
                                  : ''
                              }`}
                            >
                              <STablet.AreaText>
                                <STablet.ImageSolution
                                  src={url ?? R.images.playstore_icon}
                                />
                              </STablet.AreaText>
                            </BaseCol>
                          )
                        })}
                      </BaseRow>
                    )}
                  </BaseCol>
                )
              })}
          </div>

          {item?.benefitOfSolutions && (
            <STablet.BenefitWrapper ref={inViewBenefit.ref}>
              <STablet.Divider />
              <STablet.TitleBenefit
                className={`${
                  inViewBenefit.inView ? 'text-opacity-left-to' : ''
                }`}
              >
                {item?.benefitOfSolutions?.title}
              </STablet.TitleBenefit>
              <STablet.AreaText>
                {item?.benefitOfSolutions?.benefits?.map((item, idx) => {
                  return (
                    <STablet.ItemReasonBenefit
                      key={idx}
                      gutter={[10, 10]}
                      justify={'center'}
                      align={'middle'}
                    >
                      <STablet.TextTitleOfBenefit
                        className={`${
                          inViewBenefit.inView ? 'text-opacity-up-to' : ''
                        }`}
                      >
                        {item?.title ? `${item?.title}:` : ''}
                      </STablet.TextTitleOfBenefit>
                    </STablet.ItemReasonBenefit>
                  )
                })}
              </STablet.AreaText>
            </STablet.BenefitWrapper>
          )}

          <STablet.Divider />

          {item?.serviceSuport && (
            <STablet.ServiceSuportWrapper ref={inViewService.ref}>
              <STablet.TitleServiceSuport
                className={`${
                  inViewBenefit.inView ? 'text-opacity-right-to' : ''
                }`}
              >
                {item?.serviceSuport?.title}
              </STablet.TitleServiceSuport>
              <STablet.AreaText>
                {item?.serviceSuport?.services?.map((item, idx) => {
                  return (
                    <STablet.ItemReasonServiceSuport
                      key={idx}
                      gutter={[20, 10]}
                      align={'middle'}
                    >
                      <BaseCol
                        className={`${
                          inViewService.inView ? 'text-opacity-left-to' : ''
                        }`}
                      >
                        <STablet.TextTitleOfService>
                          {item?.title ? `${item?.title}:` : ''}
                        </STablet.TextTitleOfService>
                      </BaseCol>
                      <BaseCol
                        className={`${
                          inViewService.inView ? 'text-opacity-right-to' : ''
                        }`}
                      >
                        <STablet.TextDescriptionOfService>
                          {item?.description ?? ''}
                        </STablet.TextDescriptionOfService>
                      </BaseCol>
                    </STablet.ItemReasonServiceSuport>
                  )
                })}
              </STablet.AreaText>
            </STablet.ServiceSuportWrapper>
          )}

          <STablet.Divider />
          {item?.whyChoose && (
            <STablet.WhyChooseWrapper ref={inViewReason.ref}>
              <STablet.TitleWhy
                className={`${
                  inViewReason.inView ? 'text-opacity-right-to' : ''
                }`}
              >
                {item?.whyChoose?.title}
              </STablet.TitleWhy>
              <STablet.AreaText>
                {item?.whyChoose?.reasons?.map((item, idx) => {
                  return (
                    <STablet.ItemReasonWhy
                      key={idx}
                      gutter={[10, 10]}
                      align={'middle'}
                    >
                      <BaseCol>
                        <BaseRow
                          gutter={[10, 10]}
                          align={'middle'}
                          wrap={false}
                        >
                          <S.LeftPointReasonWhy
                            className={`${
                              inViewReason.inView ? 'text-opacity-left-to' : ''
                            }`}
                          />
                          <BaseCol
                            className={`${
                              inViewReason.inView ? 'text-opacity-right-to' : ''
                            }`}
                          >
                            <S.TextTitleOfReason>
                              {item?.title ? `${item?.title}:` : ''}
                            </S.TextTitleOfReason>
                          </BaseCol>
                        </BaseRow>
                      </BaseCol>
                      <BaseCol
                        className={`${
                          inViewReason.inView ? 'text-opacity-left-to' : ''
                        }`}
                      >
                        <STablet.TextDescriptionOfReason>
                          {item?.description ?? ''}
                        </STablet.TextDescriptionOfReason>
                      </BaseCol>
                    </STablet.ItemReasonWhy>
                  )
                })}
              </STablet.AreaText>
            </STablet.WhyChooseWrapper>
          )}
          <S.AreaText />
          <S.TextLongDescription
            className={`${inViewReason.inView ? 'text-opacity-up-to' : ''}`}
          >
            {TextFooter}
          </S.TextLongDescription>
        </STablet.LeftWrapper>
      </STablet.ContentWrapper>
    </STablet.RootWrapper>
  )
}
