import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { PaddingMain } from 'app/constant'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'
export const HeaderWrapper = styled(BaseCol)<{ isDesktop?: boolean }>`
  padding: ${props =>
    props?.isDesktop
      ? `0px ${PaddingMain.Desktop.defaultHz}`
      : `0px ${PADDING.md}`};
  padding-top: 5rem;
`

export const TextTitleHeader = styled.span`
  color: ${colors.white};
  font-size: 3rem;
  font-weight: ${FONT_WEIGHT.semibold};
`

export const TextDescriptionHeader = styled.span`
  color: ${colors.white};
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.medium};
`
export const AreaHeader = styled(BaseCol)`
  padding-bottom: ${PADDING.xxxxl};
`
export const ButtonHeader = styled(BaseButton)`
  background-color: var(--red-orange-color);
  color: ${colors.black};
  padding: ${PADDING.xxl} ${PADDING.xxxxl};
  border-color: var(--red-orange-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
  transition: all 1s ease;
  &:hover {
    color: ${colors.white} !important;
    border-color: ${colors.white} !important;
    background-color: transparent;
    transition: all 1s ease;
  }
`
