// Authentication
export const AUTH_PATH = '/auth'
export const LOGOUT_PATH = '/logout'
export const LOGIN_PATH = 'login'
export const AUTH_LOGIN_PATH = '/auth/login'

// Main
export const DASHBOARD_PATH = '/'
export const SOLUTIONS_PATH = '/solutions'
export const SOLUTIONS_DETAIL_PATH = '/solutions/detail'
export const VISION_PATH = '/vision'
export const PROGRAM_PATH = '/program'
export const BLOG_PATH = '/blog'
export const CONTACT_PATH = '/contact'
export const PROFILE_PATH = '/profile'
