import { IBodyCreateContact, requestCreateContact } from 'app/api/contact.api'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { PHONE_REGEX, REG_EMAIL, useResponsive } from 'parkway-web-common'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import * as S from './styles'
import * as STablet from './styles.tablet'
import { useNavigate } from 'react-router'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
interface DataForm {
  first_name: string
  last_name: string
  email: string
  company: string
  phone: string
  job_title: string
}

const ContactContainer = () => {
  const { ref, inView } = useInView({ triggerOnce: true })

  const { isDesktop } = useResponsive()

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (data: DataForm) => {
    try {
      setLoading(true)

      const dataToSend: IBodyCreateContact = {
        descriptions: data?.company ?? '-',
        email: [data?.email ?? ''],
        phone: [data?.phone ?? ''],
        company_name: data?.company ?? '',
        name: `${data?.first_name ?? ''} ${data?.last_name ?? ''}`,
      }

      await requestCreateContact(dataToSend)

      notificationController.success({
        message: 'Successfully',
        description: 'We will get back to you soon.',
      })
      navigate(DASHBOARD_PATH)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const initValues = {
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    phone: '',
    job_title: '',
  }

  if (isDesktop) {
    return (
      <S.RootWrapper ref={ref}>
        <BaseCol xl={12}>
          <S.TextTitle>{'Get Started'}</S.TextTitle>
          <S.AreaText>
            <S.TextDescription>
              {'Let’s give your business what it needs to grow.'}
            </S.TextDescription>
          </S.AreaText>
        </BaseCol>
        <BaseCol>
          <S.FooterWrapper>
            <S.LeftFooterWrapper>
              <S.TextLeftFooterWrapper
                className={inView ? 'text-opacity-left-to' : ''}
              >
                {`CyberDX is a trusted partner. We are a reliable and trustworthy partner who will always put your needs first. We are committed to building long-term relationships with our clients, and we are always looking for ways to exceed your expectations.`}
              </S.TextLeftFooterWrapper>
            </S.LeftFooterWrapper>
            <S.RightFooterWrapper>
              <BaseForm
                layout="vertical"
                onFinish={handleSubmit}
                requiredMark="optional"
                initialValues={initValues}
              >
                <BaseRow gutter={20}>
                  <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
                    <S.FormItem
                      name="first_name"
                      label={'First name'}
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập tên',
                        },
                      ]}
                    >
                      <S.FormInput placeholder={'First name'} required />
                    </S.FormItem>
                    <S.FormItem
                      label={'Email'}
                      name="email"
                      rules={[
                        {
                          pattern: REG_EMAIL,
                          message: 'Please enter the correct email format.',
                        },
                      ]}
                    >
                      <S.FormInput />
                    </S.FormItem>
                    <S.FormItem
                      label={'Phone'}
                      name="phone"
                      rules={[
                        {
                          pattern: PHONE_REGEX,
                          message:
                            'Please enter the correct phone number format.',
                        },
                      ]}
                    >
                      <S.FormInput required />
                    </S.FormItem>
                  </BaseCol>
                  <BaseCol className={inView ? 'text-opacity-right-to' : ''}>
                    <S.FormItem name="last_name" label={'Last name'} rules={[]}>
                      <S.FormInput placeholder={'Last name'} />
                    </S.FormItem>
                    <S.FormItem
                      label={'Company'}
                      name="company"
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the company name.',
                        },
                      ]}
                    >
                      <S.FormInput placeholder={'Company'} required />
                    </S.FormItem>
                    <S.FormItem label={'Job title'} name="job_title">
                      <S.FormInput placeholder={'Job title'} />
                    </S.FormItem>
                  </BaseCol>
                </BaseRow>
                <BaseForm.Item noStyle>
                  <S.ButtonSubmit loading={loading} htmlType="submit">
                    {'Submit'}
                  </S.ButtonSubmit>
                </BaseForm.Item>
              </BaseForm>
            </S.RightFooterWrapper>
          </S.FooterWrapper>
        </BaseCol>
      </S.RootWrapper>
    )
  }

  return (
    <STablet.RootWrapper ref={ref}>
      <BaseCol>
        <STablet.TextTitle>{'Get Started'}</STablet.TextTitle>
        <STablet.AreaText>
          <STablet.TextDescription>
            {'Let’s give your business what it needs to grow.'}
          </STablet.TextDescription>
        </STablet.AreaText>
      </BaseCol>
      <BaseCol>
        <STablet.FooterWrapper>
          <STablet.LeftFooterWrapper>
            <STablet.TextLeftFooterWrapper
              className={inView ? 'text-opacity-left-to' : ''}
            >
              {`CyberDX is a trusted partner. We are a reliable and trustworthy partner who will always put your needs first. We are committed to building long-term relationships with our clients, and we are always looking for ways to exceed your expectations.`}
            </STablet.TextLeftFooterWrapper>
          </STablet.LeftFooterWrapper>
          <STablet.RightFooterWrapper>
            <BaseForm
              layout="vertical"
              onFinish={handleSubmit}
              requiredMark="optional"
              initialValues={initValues}
            >
              <BaseRow gutter={20}>
                <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
                  <STablet.FormItem
                    name="first_name"
                    label={'First name'}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your name.',
                      },
                    ]}
                  >
                    <STablet.FormInput placeholder={'First name'} required />
                  </STablet.FormItem>
                  <STablet.FormItem
                    label={'Email'}
                    name="email"
                    rules={[
                      {
                        pattern: REG_EMAIL,
                        message: 'Please enter the correct email format.',
                      },
                    ]}
                  >
                    <STablet.FormInput />
                  </STablet.FormItem>
                  <STablet.FormItem
                    label={'Phone'}
                    name="phone"
                    rules={[
                      {
                        pattern: PHONE_REGEX,
                        message:
                          'Please enter the correct phone number format.',
                      },
                    ]}
                  >
                    <STablet.FormInput required />
                  </STablet.FormItem>
                </BaseCol>
                <BaseCol className={inView ? 'text-opacity-right-to' : ''}>
                  <STablet.FormItem
                    name="last_name"
                    label={'Last name'}
                    rules={[]}
                  >
                    <STablet.FormInput placeholder={'Last name'} />
                  </STablet.FormItem>
                  <STablet.FormItem
                    label={'Company'}
                    name="company"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the company name.',
                      },
                    ]}
                  >
                    <STablet.FormInput placeholder={'Company'} required />
                  </STablet.FormItem>
                  <STablet.FormItem label={'Job title'} name="job_title">
                    <STablet.FormInput placeholder={'Job title'} />
                  </STablet.FormItem>
                </BaseCol>
              </BaseRow>
              <BaseForm.Item noStyle>
                <STablet.ButtonSubmit loading={loading} htmlType="submit">
                  {'Submit'}
                </STablet.ButtonSubmit>
              </BaseForm.Item>
            </BaseForm>
          </STablet.RightFooterWrapper>
        </STablet.FooterWrapper>
      </BaseCol>
    </STablet.RootWrapper>
  )
}
export default ContactContainer
