import { CONTACT_PATH } from 'app/components/router/route-path'
import * as S from './styles'
import { useInView } from 'react-intersection-observer'
import { useNavigate } from 'react-router'
import { useResponsive } from 'parkway-web-common'
const HeaderHome = () => {
  const { inView, ref } = useInView({ triggerOnce: true })
  const navigate = useNavigate()
  const { isDesktop } = useResponsive()

  return (
    <S.HeaderWrapper
      ref={ref}
      xl={15}
      xxl={14}
      className={inView ? 'opacity-left-to' : ''}
      isDesktop={isDesktop}
    >
      <S.AreaHeader>
        <S.TextTitleHeader>
          {'Intelligent Software Solutions and AI'}
        </S.TextTitleHeader>
      </S.AreaHeader>
      <S.AreaHeader xl={13} xxl={12}>
        <S.TextDescriptionHeader>
          {'Power of Technology to Drive Business Growth and Innovation'}
        </S.TextDescriptionHeader>
      </S.AreaHeader>
      <S.AreaHeader>
        <S.ButtonHeader onClick={() => navigate(CONTACT_PATH)}>
          {'Learn more'}
        </S.ButtonHeader>
      </S.AreaHeader>
    </S.HeaderWrapper>
  )
}

export default HeaderHome
