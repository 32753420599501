export default {
  see_more: 'See more',
  find: 'Find',
  address: 'Address',
  contact: 'Contact',
  language: 'Language',
  vietnamese: 'Vietnamese',
  english: 'English',
  foreign_language: 'Foreign language',
  home: 'Home',
  company_name: 'Company name',
  contact_name: 'Contact name',
  phone: 'Phone',
  email: 'Email',
  send: 'Send',
  regex_name: 'Name must be in the correct format.',
  regex_user_name:
    'User name must be in the correct format (No spaces allowed)',
  login: 'Log in',
  user_name: 'Username',
  password: 'Password',
  code_verify: 'Verify code',
  send_code: 'Send code',
  login_success: 'Logged in successfully',
  forgot_password: 'Forgot password',
  require_field: 'Please enter "{{field}}", this field is required',
  minimum_field: 'Please enter at least {{count}} characters',
  maximum_field: 'Please enter up to {{count}} characters',
  register: 'Register',
  confirm_password: 'Confirm Password',
  verify_by_phone: 'Verify by phone number',
  verify_by_email: 'Verify by email',
  add_other_role: 'Add other role',
  message_verify_phone:
    'Please check your Zalo messages from phone number {{phone}} to get the verification code.',
  message_verify_email:
    'Please check your email inbox (including spam folder) for the verification code sent to "{{email}}".',
  re_send_verify_code: 'Resend verification code',
  resend: 'Resend',
  time_resend_verify_code: 'Resend after {{timer}} seconds',
  verify: 'Verify',
  logout: 'Log out',
  full_name: 'Full name',
  using_by: 'Using by',
  require_email: 'Please enter the email',
  require_contact_name: 'Please enter the contact name',
  regex_email: 'Please enter the correct email format (ex: abc@gmail.com)',
  regex_phone: 'Please enter the correct phone format (ex: 0965259441)',
  next: 'Next',
  back: 'Back',
  please_enter_correct_format: 'Please enter correct format',
  medical_examination: 'Medical examination',
  human_resources: 'Human resources',
  finance: 'Finance',
  inventory_management: 'Inventory management',
  purchasing_management: 'Purchasing management',
  customer_care: 'Customer care',
  clinic_management: 'Clinic management',
  company: 'Company',
  organizational_chart: 'Organizational chart',
  announcement: 'Announcement',
  internal_news: 'Internal news',
  onboarding: 'Onboarding',
  report: 'Report',
  marketing: 'Marketing',
  accounting: 'Accounting',
  work_schedule: 'Work schedule',
  attendance_record: 'Attendance record',
  mailbox: 'Mailbox',
  my_requests: 'My requests',
  my_tasks: 'My tasks',
  my_income: 'My income',
  dashboard: 'Dashboard',
  profile: 'Profile',
  read_all: 'Read all',
  view_all: 'View all',
  remember_me: 'Remember me',
  parkway_dental: 'Parkway dental',
  description_parkway_auth:
    'Parkway Dental is a comprehensive and professional dental system, specializing in orthodontics and implants, fully meeting the dental needs of our customers.',
  development: 'Development',
  customers: 'Customers',
  region: 'Region',
  revenue: 'Revenue',
  dashboard_common: {
    total_revenue: 'Total revenue',
    pending_revenue: 'Pending revenue',
    actual_revenue: 'Actual revenue',
    compared_to_last_month: ' Compared to last month',
    today_revenue: `Today's revenue`,
    branch_name: 'Branch name',
    overall_today_summary: `Overall today's summary`,
    area: 'Area',
    ordinal_number: 'No.',
    clinic: 'Clinic',
  },
  notification: 'Notification',
  not_done: 'Not done',
  done: 'Dơn',
  in_progress: 'In progress',
  see_detail: 'See detail',
  mail: 'Mail',
  new_mail: 'New mail',
  important: 'Important',
  show_less: 'Show less',
  collapse: 'Collapse',
  last_time_update: 'Last time update',
  service: 'Service',
  report_001: 'Report 001 - Measure Conversion from Lead Quantity to Check-in',
  report_002:
    'Report 002 - Measure the Effectiveness of New Customer Service Consultation (Team Call)',
  report_003:
    'Report 003 - Measure the Effectiveness of Old Customer Service Consultation (Team Call)',
  report_004: 'Report 004 - Measure the Effectiveness of Clinic Closing',
  report_005: 'Report 005 - Revenue Report for Each Branch by Day-Week-Month',
  report_006: 'Report 006 - Revenue Report for Each Branch by Product Group',
  report_007: 'Report 007 - Revenue Report by Customer Source',
  report_008: 'Report 008 - Top 10 Products System-wide',
  report_009: 'Report 009 - User Group Report by Product',
  report_010: 'Report 010 - User Group Report by Region',
  report_011: 'Report 011 - Top Spenders by Region',
  report_012: 'Report 012 - Profit and Loss Statement',
  spent_actual: 'Spent Actual',
  percent_budget_using: '% budget đã chi',
  planing: 'Planing',
  actuals: 'Actuals',
  cpql: 'CPQL',
  price: 'Price',
  percent_complete_target_api: '% Đạt KPI',
  number_checkin: 'Number checkin',
  number_q_lead: 'Number Qlead',
  see_with: 'See with',
  week: 'Week',
  choose_week: 'Choose week',
  name_employee: 'Employee Name',
  number_date_re_cared: 'Number of Data Re-cared',
  number_off_appointments_scheduled: 'Number of Appointments Scheduled',
  number_check_in_achieved: 'Number of Check-ins Achieved',
  crv_1: 'CRV 1',
  crv_2: 'CRV 2',
  crv_1_growth_in_period: '% Growth in CRV 1 during the period',
  crv_2_growth_in_period: '% Growth in CRV 2 during the period',
  week1: 'Tuần 1',
  week2: 'Tuần 2',
  week3: 'Tuần 3',
  week4: 'Tuần 4',
  total_revenue_month: 'Tổng doanh thu tháng {{month}}',
  total_revenue_target: 'Tổng doanh thu target',
  percent_of_target_complete: '% đạt được',
  month1: 'Tháng 1',
  month2: 'Tháng 2',
  month3: 'Tháng 3',
  month4: 'Tháng 4',
  month5: 'Tháng 5',
  month6: 'Tháng 6',
  month7: 'Tháng 7',
  month8: 'Tháng 8',
  month9: 'Tháng 9',
  month10: 'Tháng 10',
  month11: 'Tháng 11',
  month12: 'Tháng 12',
  total_revenue_year: 'Tổng doanh thu năm {{year}}',
  group_product: 'Nhóm sản phẩm',
  a1_implant: 'A1 - Implant',
  a2_implant: 'A2 - Implant',
  a3_implant: 'A3 - Implant',
  total_revenue_actual: 'Tổng doanh thu thực tế',
  total_revenue_planned: 'Tổng doanh thu Kế hoạch',
  group_customer: 'Nhóm khách hàng',
  revenue_week: 'Doanh thu tuần {{week}}',
  hcm_area: 'Khu vực Hồ Chí Minh',
  hanoi_area: 'Khu vực Hà Nội',
  digital_ad: 'Quảng cáo digital',
  percentage_of_total_revenue: '% trên tổng doanh thu',
  system_wide: 'Cả hệ thống',
  product: 'Sản phẩm',
  quarter_in_year: 'Quý trong năm',
  gender: 'Giới tính',
  age: 'Độ tuổi',
  cases_in_product_group: 'Số ca thuộc nhóm sản phẩm',
  fixed_braces: 'Chỉnh nha cố định',
  other_products: 'Sản phẩm khác',
  revenue_of_product_group: 'Doanh thu nhóm sản phẩm',
  cases_in_area: 'Số ca thuộc khu vực',
  revenue_of_area: 'Doanh thu thuộc khu vực',
  province: 'Tỉnh',
}
