import { BaseLayout } from 'app/components/common/BaseLayout'
import { LAYOUT } from 'parkway-web-common'
import styled from 'styled-components'

interface Header {
  $isTwoColumnsLayoutHeader: boolean
  isDesktop?: boolean
}

export const Header = styled(BaseLayout.Header)<Header>`
  background-color: transparent;
  padding: ${LAYOUT.mobile.paddingVertical}
    ${props =>
      !props?.isDesktop
        ? LAYOUT.mobile.paddingVertical
        : LAYOUT.mobile.paddingHorizontal} !important;
  height: unset !important;
`
