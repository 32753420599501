import { memo } from 'react'
import { isEqual } from 'lodash'
import { SolutionDetail } from 'app/containers/SolutionsDetail'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const SolutionsDetailCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.solutions)}</PageTitle>
      <SolutionDetail />
    </>
  )
}
export const SolutionsDetailPage = memo(SolutionsDetailCpn, isEqual)
