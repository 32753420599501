import styled from 'styled-components'
import { BaseRow } from '../common/BaseRow'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import { PaddingMain } from 'app/constant'
import { BaseCol } from '../common/BaseCol'
import { BaseButton } from '../common/BaseButton'

export const RootWrapper = styled(BaseRow)<{ isDesktop?: boolean }>`
  padding: ${PADDING.xxxxl}
    ${props => (props.isDesktop ? PaddingMain.Desktop.defaultHz : PADDING.md)};
  background-color: black;
`

export const InfoWrapper = styled(BaseCol)``

export const TextTitleInfo = styled.span``

export const TextValueInfo = styled.span`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
`

export const AreaText = styled.div`
  padding: ${PADDING.xxs} 0px;
`

export const CurrentPath = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const HomeText = styled.span`
  color: var(--red-orange-color);
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.medium};
  transition: all 1s ease;
`

export const ButtonPath = styled.button`
  border: none;
  background-color: transparent;
  color: var(--white);
  font-weight: ${FONT_WEIGHT.medium};
  padding: ${PADDING.xxxs} 0px;
  cursor: pointer;
  transition: all 1s ease;
  &:hover {
    color: var(--red-orange-color);
    transition: all 1.25s ease;
  }
`

export const ButtonLearnMore = styled(BaseButton)`
  background-color: var(--red-orange-color);
  color: ${colors.black};
  padding: ${PADDING.xl} ${PADDING.xxxl};
  border-color: var(--red-orange-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  transition: all 1s ease;
  &:hover {
    color: ${colors.white} !important;
    border-color: ${colors.white} !important;
    background-color: transparent;
    transition: all 1s ease;
  }
`
