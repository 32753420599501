import { BurgerIcon } from 'app/components/common/BurgerIcon'
import {
  FONT_SIZE,
  FONT_WEIGHT,
  LAYOUT,
  PADDING,
  media,
} from 'parkway-web-common'
import styled, { css } from 'styled-components'
import { BaseCol } from '../common/BaseCol'
import { BaseCollapse } from '../common/BaseCollapse'

export const HeaderActionWrapper = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 1.25rem;

    @media only screen and ${media.md} {
      font-size: 1.625rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }
`

export const DropdownCollapse = styled(BaseCollapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
    font-size: 0.875rem;

    color: var(--primary-color);

    @media only screen and ${media.md} {
      font-size: 1rem;
    }
  }

  & > .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default;

    & > span[role='img'] {
      display: none;
    }
  }
`

export const BurgerCol = styled(BaseCol)`
  z-index: 999;
  display: flex;
`

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${props =>
    props?.isCross &&
    css`
      color: var(--text-secondary-color);
    `};
`

export const SearchColumn = styled(BaseCol)`
  padding: ${LAYOUT.mobile.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`

interface ProfileColumn {
  $isTwoColumnsLayout: boolean
}

export const ProfileColumn = styled(BaseCol)<ProfileColumn>``

export const CurrentPath = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const HomeText = styled.span`
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.medium};
  transition: all 1s ease;
  &:hover {
    transition: all 1.25s ease;
  }
`
export const ButtonPath = styled.button<{ isFocus?: boolean }>`
  border: none;
  background-color: transparent;
  font-size: ${FONT_SIZE.xs};
  color: ${props =>
    props?.isFocus ? 'var(--red-orange-color)' : 'var(--white)'};
  font-weight: ${FONT_WEIGHT.medium};
  padding: 0px ${PADDING.xs};
  padding-bottom: ${PADDING.xxxxs};
  cursor: pointer;
  transition: all 1s ease;
  &:hover {
    color: var(--red-orange-color);
    transition: all 1.25s ease;
  }
`
