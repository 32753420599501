import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { BaseCol } from '../common/BaseCol'
import { BaseRow } from '../common/BaseRow'
import {
  BLOG_PATH,
  CONTACT_PATH,
  SOLUTIONS_PATH,
  VISION_PATH,
} from '../router/route-path'
import * as S from './styles'
import { useResponsive } from 'parkway-web-common'

const FooterLayout = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isDesktop } = useResponsive()
  return (
    <S.RootWrapper gutter={10} justify={'space-between'} isDesktop={isDesktop}>
      <BaseCol xl={7}>
        <S.InfoWrapper>
          <S.ButtonPath>
            <BaseRow align={'middle'}>
              <BaseCol>
                <S.HomeText>{'CyberDX'}</S.HomeText>
              </BaseCol>
            </BaseRow>
          </S.ButtonPath>
          <S.AreaText>
            <S.TextValueInfo>{'(+84) 837136363'}</S.TextValueInfo>
          </S.AreaText>
          <S.AreaText>
            <S.TextValueInfo>
              {'2130 Redwood Highway Greenbare CA 94901'}
            </S.TextValueInfo>
          </S.AreaText>
          <S.AreaText>
            <S.TextValueInfo>{'sales@cyberdx.tech'}</S.TextValueInfo>
          </S.AreaText>
        </S.InfoWrapper>
      </BaseCol>

      <BaseCol xl={10}>
        <BaseCol>
          <S.ButtonPath onClick={() => navigate(SOLUTIONS_PATH)}>
            {t(R.strings.solutions)}
          </S.ButtonPath>
        </BaseCol>
        <BaseCol>
          <S.ButtonPath onClick={() => navigate(VISION_PATH)}>
            {t(R.strings.vision)}
          </S.ButtonPath>
        </BaseCol>

        <BaseCol>
          <S.ButtonPath onClick={() => navigate(BLOG_PATH)}>
            {t(R.strings.blog)}
          </S.ButtonPath>
        </BaseCol>
        <S.AreaText>
          <S.ButtonLearnMore onClick={() => navigate(CONTACT_PATH)}>
            {'Learn more'}
          </S.ButtonLearnMore>
        </S.AreaText>
      </BaseCol>
      <BaseCol xl={2}></BaseCol>
    </S.RootWrapper>
  )
}

export default FooterLayout
