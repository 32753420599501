import { BaseCol } from 'app/components/common/BaseCol'
import * as S from './styles'
import * as STablet from './styles.tablet'
import { useInView } from 'react-intersection-observer'
import { CONTACT_PATH } from 'app/components/router/route-path'
import { useNavigate } from 'react-router'
import { useResponsive } from 'parkway-web-common'

const OurPartnerHome = () => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const navigate = useNavigate()
  const { isDesktop } = useResponsive()
  if (isDesktop) {
    return (
      <S.RootWrapper ref={ref}>
        <S.HeadWrapper gutter={0}>
          <S.ItemHeader>
            <S.TitlePartner className={inView ? 'text-opacity-left-to' : ''}>
              {'Our partner'}
            </S.TitlePartner>
          </S.ItemHeader>
          <S.ItemHeader>
            <S.TitleBranchPartner
              className={inView ? 'text-opacity-up-to' : ''}
              style={{ fontFamily: 'Comic Sans MS, Comic Sans, cursive' }}
            >
              {'Cadac'}
            </S.TitleBranchPartner>
          </S.ItemHeader>
          <S.ItemHeader>
            <S.TitleBranchPartner
              className={inView ? 'text-opacity-up-to' : ''}
              style={{ fontFamily: 'Chalkduster, fantasy' }}
            >
              {'Kiosk master'}
            </S.TitleBranchPartner>
          </S.ItemHeader>
          <S.ItemHeader>
            <S.TitleBranchPartner
              className={inView ? 'text-opacity-up-to' : ''}
              style={{ fontFamily: 'Bradley Hand, cursive' }}
            >
              {'OpenAI'}
            </S.TitleBranchPartner>
          </S.ItemHeader>
          <S.ItemHeader>
            <S.TitleBranchPartner
              className={inView ? 'text-opacity-up-to' : ''}
              style={{ fontFamily: 'Bradley Hand, cursive' }}
            >
              {'KNG'}
            </S.TitleBranchPartner>
          </S.ItemHeader>
        </S.HeadWrapper>
        <S.ContentWrapper>
          <S.LeftContentWrapper>
            <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
              <p className="title">{'Are You Ready to'}</p>
            </BaseCol>
            <BaseCol className={inView ? 'text-opacity-right-to' : ''}>
              <p className="title">{'Accelerate Your Business?'}</p>
            </BaseCol>
          </S.LeftContentWrapper>
          <S.RightContentWrapper>
            <S.AreaPaddingWrapper
              className={inView ? 'text-opacity-left-to' : ''}
            >
              <S.TextRight>
                {`CyberDX, a leading AI and software development company with a presence in both Vietnam and the United States, empowers businesses to accelerate their growth and achieve their full potential. Through its comprehensive suite of AI-powered solutions, CyberDX helps businesses across industries transform their operations, enhance decision-making, and gain a competitive edge.`}
              </S.TextRight>
            </S.AreaPaddingWrapper>
            <S.AreaPaddingWrapper
              className={inView ? 'text-opacity-left-to' : ''}
            >
              <S.ButtonHeader onClick={() => navigate(CONTACT_PATH)}>
                {'Learn more'}
              </S.ButtonHeader>
            </S.AreaPaddingWrapper>
          </S.RightContentWrapper>
        </S.ContentWrapper>
      </S.RootWrapper>
    )
  }

  return (
    <STablet.RootWrapper ref={ref}>
      <STablet.HeadWrapper gutter={0}>
        <STablet.ItemHeader>
          <STablet.TitlePartner
            className={inView ? 'text-opacity-left-to' : ''}
          >
            {'Our partner'}
          </STablet.TitlePartner>
        </STablet.ItemHeader>
        <STablet.ItemHeader>
          <STablet.TitleBranchPartner
            className={inView ? 'text-opacity-up-to' : ''}
            style={{ fontFamily: 'Comic Sans MS, Comic Sans, cursive' }}
          >
            {'Cadac'}
          </STablet.TitleBranchPartner>
        </STablet.ItemHeader>
        <STablet.ItemHeader>
          <STablet.TitleBranchPartner
            className={inView ? 'text-opacity-up-to' : ''}
            style={{ fontFamily: 'Chalkduster, fantasy' }}
          >
            {'Kiosk master'}
          </STablet.TitleBranchPartner>
        </STablet.ItemHeader>
        <STablet.ItemHeader>
          <STablet.TitleBranchPartner
            className={inView ? 'text-opacity-up-to' : ''}
            style={{ fontFamily: 'Bradley Hand, cursive' }}
          >
            {'OpenAI'}
          </STablet.TitleBranchPartner>
        </STablet.ItemHeader>
        <STablet.ItemHeader>
          <STablet.TitleBranchPartner
            className={inView ? 'text-opacity-up-to' : ''}
            style={{ fontFamily: 'Bradley Hand, cursive' }}
          >
            {'KNG'}
          </STablet.TitleBranchPartner>
        </STablet.ItemHeader>
      </STablet.HeadWrapper>
      <STablet.ContentWrapper>
        <STablet.LeftContentWrapper>
          <BaseCol className={inView ? 'text-opacity-left-to' : ''}>
            <p className="title">{'Are You Ready to'}</p>
          </BaseCol>
          <BaseCol className={inView ? 'text-opacity-right-to' : ''}>
            <p className="title">{'Accelerate Your Business?'}</p>
          </BaseCol>
        </STablet.LeftContentWrapper>
        <STablet.RightContentWrapper>
          <STablet.AreaPaddingWrapper
            className={inView ? 'text-opacity-left-to' : ''}
          >
            <STablet.TextRight>
              {`CyberDX, a leading AI and software development company with a presence in both Vietnam and the United States, empowers businesses to accelerate their growth and achieve their full potential. Through its comprehensive suite of AI-powered solutions, CyberDX helps businesses across industries transform their operations, enhance decision-making, and gain a competitive edge.`}
            </STablet.TextRight>
          </STablet.AreaPaddingWrapper>
          <STablet.AreaPaddingWrapper
            className={inView ? 'text-opacity-left-to' : ''}
          >
            <STablet.ButtonHeader onClick={() => navigate(CONTACT_PATH)}>
              {'Learn more'}
            </STablet.ButtonHeader>
          </STablet.AreaPaddingWrapper>
        </STablet.RightContentWrapper>
      </STablet.ContentWrapper>
    </STablet.RootWrapper>
  )
}

export default OurPartnerHome
