import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { PaddingMain } from 'app/constant'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PaddingMain.Desktop.defaultHz} ${PADDING.md};
`
export const TextTitle = styled.p`
  color: ${colors.white};
  font-size: 50px;
  font-weight: ${FONT_WEIGHT.semibold};
`
export const DescriptionTitle = styled.p`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.regular};
`

export const ItemWrapper = styled(BaseRow)<{ isNoneBottomBorder?: boolean }>`
  border: 0.5px solid ${colors.grayC4};
  width: 100%;
  border-bottom: ${props =>
    props.isNoneBottomBorder ? '0px' : `0.5px solid ${colors.grayC4}`};
`

export const SmallWrapper = styled.div<{
  isNoneRightBorder?: boolean
  isLeftBorder?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${PaddingMain.Desktop.defaultHz};
  width: 100%;
  height: 100%;
  color: ${colors.white};
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xxxl};
  background-image: url(https://storage.cloud.google.com/cyber-dx/3-1700972237903.jpg);
  background-mode: cover;
`

export const ContentWrapper = styled(BaseCol)`
  margin-top: 3rem;
  background-color: rgba(0, 0, 0, 0.9);
`

export const BigSizeWrapper = styled.div<{ isNoneLeftBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
`

export const AreaText = styled.div`
  padding: ${PADDING.md} 0px;
`
export const TextBigSizeLayout = styled.span`
  color: ${colors.grayC4};
  font-size: ${FONT_SIZE.xs};
`
