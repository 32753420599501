import styled from 'styled-components'
import { BaseButton } from '../BaseButton'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'

export const BaseButtonVariant = styled(BaseButton)<{
  padding?: string
  fontSize?: string
}>`
  background-color: var(--red-orange-color);
  color: ${colors.black};
  padding: ${props =>
    props.padding ? `${props.padding}` : `${PADDING.xxl} ${PADDING.xxxxl}`};
  border-color: var(--red-orange-color);
  font-size: ${props =>
    props?.fontSize ? `${props?.fontSize}` : `${FONT_SIZE.md}`};
  font-weight: ${FONT_WEIGHT.medium};
  transition: all 1s ease;
  &:hover {
    color: ${colors.white} !important;
    border-color: ${colors.white} !important;
    background-color: transparent;
    transition: all 1s ease;
  }
`
